import Fetch from '@/utils/fetch.js'
import Constant from '@/utils/constant'
import {
  courseAnalystic,
  courseWatch,
  courseWatchDuration,
} from '@/api/common/common'

/**
 * 课程商城
 * @param {object} data
 * @param {number} [data.category_id]
 * @param {number} [data.course_tag_template_id]
 * @param {COURSE_TYPE} [data.course_type] -
 * @param {number} [data.class_type]
 * @param {number} [data.days]
 * */
const course = ( type, data, fn, id = '', errorFn, dataLevel ) => {
  new Fetch(`courses/${id}`, type, data, fn, errorFn, dataLevel)
}

/**
 * 课程推荐
 * @param {number} [data.category_id]
 * @param {COURSE_TYPE} [data.course_type]
 * */

const courseRecommend = ( data, fn, errorFn, dataLevel ) => {
  new Fetch('courses/recommend', 'get', data, fn, errorFn, dataLevel)
}

// 课程详情
const courseDetail = ( data, fn, errorFn, id, version ) => {
  new Fetch(`courses/${id}`, 'get', data, fn, errorFn, version)
}

// 班课计划详情
const classCourseDetail = ( course_schedule_id, fn, errorFn, version ) => {
  new Fetch(
      `course_plans/classes/schedules/${course_schedule_id}`,
      'get',
      '',
      fn,
      errorFn,
      version
  )
}

// 加入购物车
const shoppingCart = ( type, data, fn, errorFn, version ) => {
  new Fetch('carts', type, data, fn, errorFn, version)
}

//课程表
const coursePlanApi = ( id, fn, errorFn, dataLevel = 'v1' ) => {
  new Fetch(`courses/${id}/schedules`, 'get', '', fn, errorFn, dataLevel)
}

// 直播1v1课表

// 用户课程信息
const userLiveCourseDesc = ( type, data, fn, errorFn, id, version = 'v1' ) => {
  new Fetch(
      `course_plans/personal/tasks/${id}`,
      type,
      data,
      fn,
      errorFn,
      version
  )
}

// 用户课程信息
const userCourseDesc = ( type, data, fn, errorFn, id, version ) => {
  new Fetch(`courses/${id}`, type, data, fn, errorFn, version)
}

// 报名免费班课
const buyUserCourse = ( id, fn, errorFn, version ) => {
  new Fetch(`courses/${id}/plans`, 'post', '', fn, errorFn, version)
}

// 课程资料
const courseMaterials = ( id, fn, errorFn, dataLevel ) => {
  new Fetch(
      `course_plans/class_lesson/${id}/coursewares`,
      'get',
      '',
      fn,
      errorFn,
      dataLevel
  )
}

//课程资料下载地址
const getCourseMaterialUrl = ( id, fn, errorFn, dataLevel ) => {
  new Fetch(
      `coursewares/${id}`,
      'get',
      '',
      fn,
      errorFn,
      dataLevel
  )
}

// 评论列表
const courseCommentApi = ( id, data, fn, errorFn, dataLevel ) => {
  new Fetch(`courses/${id}/comments`, 'get', data, fn, errorFn, dataLevel)
}

// 提交评论
const subCourseCommentApi = ( data, fn, errorFn, dataLevel ) => {
  new Fetch('courses/comments', 'post', data, fn, errorFn, dataLevel)
}

// 相关课程
const relationCourseApi = ( id, fn, errorFn, dataLevel ) => {
  new Fetch(`courses/${id}/relevance`, 'get', '', fn, errorFn, dataLevel)
}

// 用户课程
const userCourseDetail = ( data, fn, errorFn, id, version ) => {
  new Fetch(`user/courses/${id}/plans`, 'get', data, fn, errorFn, version)
}

// 是否报名
const pastCourseDetail = ( type, data, fn, id, errorFn, version ) => {
  new Fetch(`courses/${id}/plans/status/`, type, data, fn, errorFn, version)
}

// 班课

//课列表
const classCourse = ( data, fn, course_plan_id, errorFn, version = 'v1' ) => {
  new Fetch(
      `course_plans/classes/${course_plan_id}/schedules`,
      'get',
      data,
      fn,
      errorFn,
      version
  )
}
// // 课详情
// const classCourseDetail = (data, fn, id, errorFn, version = 'v10') => {
//     new Fetch(`user/course_plans/${id}/tasks`, 'get', data, fn, errorFn, version)
// }

// 1v1课程

// 列表
const privateCourse = ( data, fn, errorFn, id, dataLevel = 'v1' ) => {
  new Fetch(
      `course_plans/personal/${id}/tasks`,
      'get',
      data,
      fn,
      errorFn,
      dataLevel
  )
}

// 课详情
const privateCourseDetail = ( data, fn, id, errorFn, dataLevel ) => {
  new Fetch(
      `user/course_plans/tasks/${id}`,
      'get',
      data,
      fn,
      errorFn,
      dataLevel
  )
}

// 回看接口
const replayCourseDetail = function (
    data,
    id,
    liveModel,
    fn,
    dataLevel = 'v1',
    errofn
) {
  if (liveModel === Constant.LIVE_MODE.CLASS) {
    return new Fetch(
        `course_plans/schedules/${id}/playback`,
        'get',
        data,
        fn,
        errofn,
        dataLevel
    )
  }else if(liveModel === Constant.LIVE_MODE.TRAINING) {
    return new Fetch(
        `training/speaking/appointments/${id}/playback`,
        'get',
        data,
        fn,
        errofn,
        dataLevel
    )
  } else {
    return new Fetch(
        `course_plans/personal/tasks/${id}/playback`,
        'get',
        data,
        fn,
        errofn,
        dataLevel = 'v1'
    )
  }
}
// todo 免费视频跳知乎 do
// const getPlayId = (course_schedule, fn, errorFn, dataLevel) => {
//   new Fetch(
//     `course_plans/classes/schedules/${course_schedule}/encrypt`,
//     'get',
//     '',
//     fn,
//     errorFn,
//     dataLevel
//   );
// };

export {
  course,
  courseDetail,
  coursePlanApi,
  courseRecommend,
  relationCourseApi,
  courseCommentApi,
  subCourseCommentApi,
  userCourseDetail,
  courseMaterials,
  shoppingCart,
  userCourseDesc,
  pastCourseDetail,
  privateCourse,
  classCourse,
  privateCourseDetail,
  replayCourseDetail,
  userLiveCourseDesc,
  classCourseDetail,
  courseAnalystic,
  courseWatch,
  courseWatchDuration,
  buyUserCourse,
  getCourseMaterialUrl,
  // getPlayId,
}
