export const indexLazyLoad = {
  data () {
    return {
      apiRequestTime: 0
    }
  },
  methods: {
    lazyLoad () {
      // 创建IntersectionObserver
      const observer = new IntersectionObserver(this.handleLazyLoad, {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      })

      // 观察所有懒加载的元素
      document.querySelectorAll('.lazy-load').forEach(element => {
        observer.observe(element)
      })
    },
    handleLazyLoad ( entries, observer ) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const element = entry.target
          if (element.id === 'sectionArticle') {
            this.getData()
          } else {
            this.$refs[ element.id ].init()
          }
          observer.unobserve(element)
        }
      })
    },
    setApiRequestTime ( time ) {
      this.apiRequestTime = time > this.apiRequestTime ? time : this.apiRequestTime
    }
  }
}

