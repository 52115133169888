import {
  ADD_CART_PRODUCT,
  DEL_CART_PRODUCT,
  CHANGE_COURSE_QTY,
  MODIFY_CART_PRODUCT,
  UPDATE_CART_PORDUCT,
  UPDATE_SELECT_PRODUCT
} from './mutation-types.js'

export default {
  [ADD_CART_PRODUCT] (state, product) {
    state.cart = state.cart.concat(product)
  },
  [DEL_CART_PRODUCT] (state, product) {
    state.cart = product //todo
  },
  [CHANGE_COURSE_QTY] (state, product) {
    state.cart = product //todo
  },
  [MODIFY_CART_PRODUCT] (state, product) {
    state.cart = []
    state.cart = state.cart.concat(product)
  },
  [UPDATE_CART_PORDUCT] (state, products) {
    state.cart = []
    state.cart = state.cart.concat(products)
  },
  [UPDATE_SELECT_PRODUCT] (state, products) {
    state.selectProduct = []
    state.selectProduct = state.selectProduct.concat(products)
  }
}
