<template>
  <div class="float-bar"
  >
    <div class="duck">
      <img src="./gif.gif" alt="">
    </div>
    <FloatBarItem v-for="item in itemList" :key="'fl'+item.name" :item="item" @itemClick="handleItemClick"></FloatBarItem>
    <FloatBarItem :item="{label: '', name: 'top', href: 'javascript:;', icon: 'icon-papa-top'}" style="color: rgba(0,0,0,0.3)" @itemClick="handleItemClick"></FloatBarItem>
  </div>
</template>

<script>
  import downloadApp from './download.png'
  import FloatBarItem from '@/components/FloatBar/components/FloatBarItem.vue'
  import FloatAds from '@/components/FloatAds/FloatAds.vue'

  export default {
    name: 'FloatBar',
    components: {
      FloatBarItem,
      FloatAds
    },
    data() {
      return{
        isShowSub: false,
        qrImage: downloadApp,
        timer: null,
        itemList: [
          {
            label: '课程咨询',
            name: 'course',
            href: '/customized',
            icon: 'icon-papa-record_selected'
          },
          {
            label: '免费公开课',
            name: 'community',
            href: '/customized',
            icon: 'icon-papa-course_selected'
          },
          {
            label: '资料领取',
            name: 'material',
            href: 'javascript:;',
            icon: 'icon-papa-data'
          },
          {
            label: '在线客服',
            name: 'service',
            href: 'javascript:;',
            icon: 'icon-papa-a-customerservice'
          },
          // {  // todo
          //   label: '意见反馈',
          // name: '',
          //   href: 'javascript:;',
          //   icon: 'icon-papa-message'
          // },
          {
            label: 'APP下载',
            name: 'download',
            href: 'javascript:;',
            icon: 'icon-papa-phone'
          }
        ]
      }
    },
    props:{
      category: {
        type: String,
        default: 'ielts'
      }
    },
    mounted(){
     // this.timer = setTimeout(() => {
     //    clickHandler()
     //  }, 3000)

    },
    beforeDestroy() {
      clearTimeout(this.timer)
      this.timer = null
    },
    methods:{
      callService() {
        clickHandler(true)
      },
      goTop () {
        document.documentElement.scrollTop = 0
      },
      handleItemClick(item){
        switch (item.name) {
          case 'service':
            clickHandler(true)
            break
          case 'download':
            window.open('https://papaen.com/download')
            break
          case 'top':
            this.goTop()
            break
          default:
            break
        }
      }
    }
  }
</script>

<style lang="less">
  @media screen and (max-width: 1400px) {
    .float-bar{
      margin-left: 43%!important;
    }
  }

.float-bar{
  position: fixed;
  bottom: 30px;
  left: 50%;
  margin-left: 620px;
  box-sizing: border-box;
  padding: 10px 0px;
  width:88px;
  background: #fff;
  font-size: 24px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  z-index: 2000;
  box-shadow: 0 2px 16px 0 rgba(61, 61, 61, 0.1);

  .duck{
    position: absolute;
    top: -48px;
    width: 48px;
    left: 16px;
    img{
      width: 100%;
    }
  }
  .float-bar-item-wrapper:last-child{
    .float-bar-item{
      border-bottom: none;
    }
  }

  &>.iconfont{
    font-size: 24px;
    color: #555;
    cursor: pointer;
  }

  &>.iconfont:before{
    margin-right: 0;
  }
  &>.qrcode{
    &>.wechat-img{
      width: 200px;
      height:200px;
      position: absolute;
      left: -210px;
      top: -20px;
      display: none;
    }
  }
  &>.qrcode:hover{
    .wechat-img{
      display: block;
    }
  }
}
</style>
