<template>
  <div class="information">
    <div class="title">完善信息</div>
    <el-form :model="informationForm" :rules="rules" ref="information">
      <el-form-item label="" prop="nickname" ref="account">
        <el-input v-model="informationForm.nickname" placeholder="您的昵称" clearable></el-input>
      </el-form-item>
      <el-form-item prop="preparation_category">
        <el-select v-model="informationForm.preparation_category" placeholder="您需要备考的考试科目">
          <el-option
              v-for="item in optionsSubject"
              :key="item.value"
              :label="item.value"
              :value="item.key">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="preparation_period">
        <el-select v-model="informationForm.preparation_period" placeholder="您的备考周期">
          <el-option
              v-for="item in optionsTerm"
              :key="item.value"
              :label="item.value"
              :value="item.key">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="learning_method">
        <el-select v-model="informationForm.learning_method" placeholder="您期望的学习方式">
          <el-option
              v-for="item in optionsLearnType"
              :key="item.value"
              :label="item.value"
              :value="item.key">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="button" @click="submitMessage('information')">确认提交</div>
  </div>
</template>

<script>
  import * as Api from '@/api/login/login.js'
  import { informationOptions, profileCheck } from '@/api/login/login.js'

  export default {
    name: 'improveInformation',
    data () {
      let validateNickname = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('账号不能为空'))
        } else {
          callback()
        }
      }
      let validateLearnMethod = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('账号不能为空'))
        } else {
          callback()
        }
      }
      return {
        optionsSubject: [],
        optionsTerm: [],
        optionsLearnType: [],
        informationForm: {
          nickname: '',
          preparation_category: '',
          preparation_period: '',
          learning_method: '',
        },
        rules: {
          nickname: [
            {validator: validateNickname, trigger: 'blur'},
          ],
          preparation_category: [
            {required: true, message: '您需要备考的考试科目', trigger: 'change'},
          ],
          preparation_period: [
            {required: true, message: '您的备考周期', trigger: 'change'},
          ],
          learning_method: [
            {required: true, message: '请选择您期望的学习方式', trigger: 'change'},
          ]

        }
      }
    },
    mounted () {
      this.getOptions()
    },
    methods: {
      submitMessage (formname) {
        this.$refs[formname].validate((valid) => {
          if (valid) {
            let params = this.informationForm
            params.register_platform = 'web'
            Api.profileCheck('post', params, res => {
              this.$message.success('提交成功')
              this.$emit('closeInformationDialog')
            })
          } else {
            return false
          }
        })

      },
      getOptions () {
        Api.informationOptions(res => {
          this.optionsSubject = res.preparation_categories
          this.optionsTerm = res.preparation_periods
          this.optionsLearnType = res.learning_methods
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  /*@import "../../assets/style/lib-base";*/

  .information {
    & > .title {
      font-size: @fontSizePrice;
      text-align: center;
      font-weight: bold;
      margin-bottom: 20px;
    }

    /deep/ .el-form {
      margin-bottom: 50px;

      & > .el-form-item {
        margin-bottom: 42px;

        input {
          width: 100%;
          border: none;
          border-bottom: 1px solid #e6e6e6;
          border-radius: 0;
        }

        .el-select {
          width: 100%;
        }
      }
    }

    .button {
      .btnGreenPurl(100%, 44px, 2px)
    }
  }

</style>
