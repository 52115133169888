import * as Config from '@/config/live'

export default {
  name: 'papaPlayer',
  data () {
    return {
      selectorId: '',
      player: null,
      TCPlayer: null,
    }
  },
  props: {
    fid: {
      type: String,
      default: '',
    },
    fullScreen: {
      type: Boolean,
      default: true,
    },
    course: {
      type: Object,
      default: () => {
        return {}
      },
    },
    courseId: {
      type: Number,
    },
  },
  mounted () {
    this.TCPlayer = window.TCPlayer
  },
  computed: {
    appID(){
      return JSON.parse(localStorage.getItem('configSUBAPPID'))
    }
  },
  methods: {
    initPlay ( fid, fullScreen ) {
      this.player = this.TCPlayer('player-container-id', {
        // player-container-id 为播放器容器 ID,必须与 html 中一致
        fileID: fid, // 请传入需要播放的视频 filID（必须）
        // appID: Config.TCPlayerAppId, // 请传入点播账号的 appID（必须）
        appID: this.appID, // 请传入点播账号的 appID（必须）
        playbackRates: [0.75, 1, 1.25, 1.5, 1.75, 2],
        controlBar: {
          fullscreenToggle: fullScreen,
          QualitySwitcherMenuButton: false,
        },
        plugins: {
          // ContinuePlay: {
          //   // 开启续播功能
          //   auto: false, //[可选] 是否在视频播放后自动续播
          //   text: "上次播放至 ", //[可选] 提示文案
          //   btnText: "恢复播放", //[可选] 按钮文案
          // },
        },
      })
      this.player.on('timeupdate', ( res ) => {
        this.$emit('onPlayerTimeupdate', this.player)
      })
      this.player.on('loadedmetadata', ( res, d2, d3 ) => {
        // this.player.currentTime(this.course.last_second);
        let videoWatchRecords =
            sessionStorage[ 'videoWatchRecords' + this.courseId ] &&
            JSON.parse(sessionStorage[ 'videoWatchRecords' + this.courseId ])
        if (videoWatchRecords) {
          if (videoWatchRecords[ this.course.id ]) {
            this.player.currentTime(videoWatchRecords[ this.course.id ])
          } else {
            this.player.currentTime(this.course.last_second)
          }
        } else {
          this.player.currentTime(this.course.last_second)
        }
        // this.$emit("onPlayerTimeupdate", this.player);
      })
      this.player.on('play', () => {
        this.$emit('onPlayerPlay', this.player)
      })
      this.player.on('pause', () => {
        this.$emit('onPlayerPause', this.player)
      })
    },
    changeFid ( fid ) {
      this.player.loadVideoByID({
        fileID: fid,
        appID: this.appID
      })
    },
    distory () {
      this.player.dispose()
    },
    getCurrentTime () {
      return this.player.currentTime()
    },
  },
  watch: {
    fid ( newVal ) {
      if (this.player) {
        this.changeFid(this.fid)
      } else {
        this.initPlay(this.fid, this.fullScreen)
      }
    },
    course ( val ) {
      if (this.player) {
        let videoWatchRecords =
            sessionStorage[ 'videoWatchRecords' + this.courseId ] &&
            JSON.parse(sessionStorage[ 'videoWatchRecords' + this.courseId ])
        if (videoWatchRecords) {
          if (videoWatchRecords[ val.id ]) {
            this.player.currentTime(videoWatchRecords[ val.id ])
          } else {
            this.player.currentTime(val.last_second)
          }
        } else {
          this.player.currentTime(val.last_second)
        }
      }
    },
  },
}
