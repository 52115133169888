import {
    SET_CATEGORY_TYPE,
    SET_FIRST_PASSWORD,
    SET_GLOBAL_ERROR,
    SET_GLOBAL_MASK,
    SET_INFORMATION_STATUS,
    SET_LOGIN_DIALOG,
    SET_PERSONAL_DIALOG
} from './mutation-types'

export default {
    setCategoryTypes: ({ commit }, { types }) => {
        commit(SET_CATEGORY_TYPE, types)
        localStorage.setItem('configCategory', JSON.stringify(types))
    },
    setLoginDialog: ({ commit }, { status }) => {
        commit(SET_LOGIN_DIALOG, status)
    },
    setGlobalMask: ({ commit }, { status }) => {
        commit(SET_GLOBAL_MASK, status)
    },
    setPersonalDialog: ({ commit }, status) => {
        commit(SET_PERSONAL_DIALOG, status)
    },
    setGlobalError: ({ commit }, error) => {
        commit(SET_GLOBAL_ERROR, error)
    },
    setInformationStats: ({ commit }, status) => {
        commit(SET_INFORMATION_STATUS, status)
    },
    setFirstPasswordStatus: ({ commit }, status) => {
        commit(SET_FIRST_PASSWORD, status)
    }
}