import * as types from '@/store/user/mutation-types.js'

export default {
  [types.SET_USER_INFO] (state, userInfo) {
    state.userInfo = userInfo
  },
  [types.SET_AUTHORIZATIONS] (state, authorization) {
    state.authorization = authorization
  },
  [types.SET_USER_NICKNAME] (state, nickname) {
    state.userInfo.nickname = nickname
    localStorage.user = JSON.stringify(state.userInfo)
  },
  [types.SET_USET_AVATAR] (state, avatar) {
    state.userInfo.avatar = avatar
    localStorage.user = JSON.stringify(state.userInfo)
  },
  [types.SET_VIDEO_DIALOG] (state, status) {
    state.videoDialog = status
  },
  [types.SET_CURRENT_VIDEO] (state, obj) {
    state.currentVideo = obj
  }
}
