<script>
  export default {
    name: 'QrcodeHover',
    data () {
      return {
        itemMap:{
          course: {
            img: 'mini_program_image_url',
            title: 'mini_program_text'
          },
          material: {
            img: 'material_qr_code_image_url',
            title: 'material_qr_code_description'
          },
          community: {
            img: 'public_class_qr_code_image_url',
            title: 'public_class_qr_code_description'
          }
        }
      }
    },
    props: {
      categoryItem: {
        type: Object,
        default: function () {
          return {}
        }
      },
      type: {
        type: String,
        default: 'course'
      }
    }
  }
</script>

<template>
  <div class="qrcode-hover-wrapper">
    <div class="qrcode-hover">
      <div class="qrcode-img">
        <img :src="categoryItem[itemMap[type].img]" alt="">
      </div>
      <div class="qrcode-title">
        {{ categoryItem[itemMap[type].title] }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
  .qrcode-hover-wrapper {
    position: absolute;
    left: -130px;
    top: -20px;
    width: 150px
  }
  .qrcode-hover-wrapper::after {
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 6px;
    background: #fff;
    transform: rotate(45deg);
    position: absolute;
    top: 20px;
    right: 24px;
  }

  .qrcode-hover {
    box-sizing: border-box;
    width: 120px;
    background: #fff;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 2px 16px 0 rgba(61, 61, 61, 0.1);


    .qrcode-img {
      width: 80px;
      height: 80px;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .qrcode-title {
      color: rgba(0, 0, 0, 0.7);
      font-size: 12px;
      font-weight: bold;
      text-align: center;
      margin-top: 4px;
      line-height: 20px;
      word-break: break-all;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }
</style>
