<template>
  <div class="nav-bar">
    <div class="nav-list">
      <div class="nav" v-for="(item) in dataNav"
           :key="item.url"
           :class="[routePath.includes(item.url) && item.url !=='/index' ? 'nav-active':'']"
      >
        <router-link v-if="item.name === '首页'" to="/index" exact :class="[routePath === '/' ? 'nav-active' : '']" @click.native="homePage">
          <div>{{ item.name }}</div>
          <div class="under-line"></div>
        </router-link>
        <template v-else>
          <div v-if="item.categoryId" class="category-menu" @click="handleShowMenu(item)">
            <div>{{ item.name }}</div>
            <div class="under-line"></div>
          </div>
          <a v-else-if="item.type === 'out'" class="category-menu category-menu-single " :href="item.url">
            <div>{{ item.name }}</div>
            <div class="under-line"></div>
          </a>
          <router-link v-else class="category-menu category-menu-single " :to="item.url" target="_self">
            <div>{{ item.name }}</div>
            <div class="under-line"></div>
          </router-link>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import { switchCategory } from '@/utils/utils'

  export default {
    name: 'NavBar',
    data () {
      return {
        hightColor: '',
        categoryId: 1,
        isShowSubMenu: false,
        menuList: [],
        dataNav: [
          {
            name: '首页',
            url: '/index',
          },
          {
            name: '雅思',
            url: '/ielts',
            categoryId: 1,
          },
          {
            name: '托福',
            url: '/toefl',
            categoryId: 2,

          },
          {
            name: 'GMAT',
            url: '/gmat',
            categoryId: 4,

          },
          {
            name: 'GRE',
            url: '/gre',
            categoryId: 3,
          },
          {
            name: 'PTE',
            url: '/pte',
            categoryId: 6
          },
          {
            name: 'A-Level',
            url: '/alevel',
            categoryId: 10
          },
          // {
          //   name: '实用英语',
          //   url: '/practical',
          //   categoryId: 5,
          //
          // },
          // {
          //   name: '日语',
          //   url: '/japanese',
          //   categoryId: 11,
          // },
          // {
          //   name: '其它类目',
          //   url: '/gre',
          //   categoryId: 10,

          // },
          // {
          //   name: "留学",
          //   url: "/pte",
          //   categoryId: 1,
          //
          // },
          // // {
          // //   name: '学术英语',
          // //   url: '/eap'
          // // },

          {
            name: '留学',
            url: '/abroad',
          },
          {
            name: '海外课业辅导',
            url: '/tutoring',
          },
          {
            name: '模考中心',
            url: '/mockCenter',
          },
          {
            name: 'APP下载',
            url: '/download',
          },
        ],
        ieltsMenu: [
          {
            url: '/ielts/index',
            name: '首页',
          },
          {
            url: '/ielts/community',
            name: '公益课',
          },
          // {
          //   url: '/ielts/activity',
          //   name: '训练营',
          // },
          {
            url: '/ielts/series',
            name: '课程商城',
          },
          {
            url: '/ielts/spoken',
            name: '口语素材',
          },
          {
            url: '/ielts/material',
            name: '资料中心',
          },
          {
            url: '/ielts/article',
            name: '精品文章',
          },
          // {
          //   url: '/ielts/prediction',
          //   name: '机经预测',
          //   tag: 'new'
          // }
        ],
        toeflMenu: [
          {
            url: '/toefl/index',
            name: '首页',
          },
          {
            url: '/toefl/community',
            name: '公益课',
          },
          {
            url: '/toefl/activity',
            name: '训练营',
          },
          {
            url: '/toefl/series',
            name: '课程商城',
          },
          {
            url: '/toefl/material',
            name: '资料中心',
          },
          {
            url: '/toefl/article',
            name: '精品文章',
          },
          // {
          //   url: '/ielts/prediction',
          //   name: '机经预测',
          //   tag: 'new'
          // }
        ],
        GMATMenu: [
          {
            url: '/gmat/index',
            name: '首页',
          },
          {
            url: '/gmat/community',
            name: '公益课',
          },
          {
            url: '/gmat/activity',
            name: '训练营',
          },
          {
            url: '/gmat/series',
            name: '课程商城',
          },
          {
            url: '/gmat/material',
            name: '资料中心',
          },
          {
            url: '/gmat/article',
            name: '精品文章',
          },
          // {
          //   url: '/ielts/prediction',
          //   name: '机经预测',
          //   tag: 'new'
          // }
        ],
        routePath: '',
        practicalMenu: [
          {
            url: '/practical/series',
            name: '课程商城',
          },
          {
            url: '/practical/material',
            name: '资料中心',
          },
          {
            url: '/practical/article',
            name: '精品文章',
          },
        ],
        otherMenu: [
          {
            name: 'GRE',
            url: '/gre',
            categoryId: 3,

          },
          {
            name: 'PTE',
            url: '/pte',
            categoryId: 6,

          },
          {
            name: '多邻国',
            url: '/duolingo',
            categoryId: 8,

          },
          // {
          //   name: '实用英语',
          //   url: '/practical',
          //   categoryId: 5,

          // },
        ]


      }
    },
    mounted () {
      this.categoryId = switchCategory(this.$route.path)
      this.menuList = this.ieltsMenu
      this.routePath = this.$route.fullPath
    },

    methods: {
      handleShowMenu ( category ) {
        console.log(this.routePath)
        // if(this.routePath.includes('activityDetail') || this.routePath.includes('course')) {
        //   this.$router.push({
        //     path: `${category.url}/index`
        //   })
        // }
        if(category.url === '/practical' || category.url === '/japanese') {
            this.$router.push({
              path: `${category.url}/series`
            })
        }else {
            this.$router.push({
              path: `${category.url}/index`
            })
        }
        console.log(category)
        this.hightColor = category.categoryId
        this.isShowSubMenu = true
        if(this.routePath === '/' || this.routePath === '/abroad' || this.routePath === '/download') {
          if(category.categoryId === 5 || category.categoryId === 11) {
            this.$router.push({
              path: `${category.url}/series`
            })
          }else {
            this.$router.push({
              path: `${category.url}/index`
            })
          }
        }
        this.$bus.$emit('fn', category)
        this.$emit('categoryId', category.categoryId)
        switch (category.categoryId) {
          case 1:
            return this.menuList = this.ieltsMenu
          case 2:
            return this.menuList = this.toeflMenu
          case 4:
            return this.menuList = this.GMATMenu
          case 5:
            return this.menuList = this.practicalMenu
          default:
            return this.menuList = []
        }
      },
      // 首页点击事件
      homePage() {
        this.$emit('categoryId', '')
      }
    },
    watch: {
      '$route': {
        deep: true,
        handler: function ( newValue ) {
          if (newValue) {
            this.routePath = newValue.fullPath
          }
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  .category-menu {
    margin: 0 auto;
  }
  .nav-bar {
    user-select: none;
    width: 100%;
    height: 100%;
    // border-bottom: 1px solid #f2f2f2;
    background: #fff;
    // position: fixed;
    top: 100px;
    z-index: 9;

    .nav-list {
      height: 100%;
      width: 1200px;
      margin: 0 auto;
      font-size: 16px;
      text-align: left;

      .nav {
        display: inline-block;
        margin-right: 20px;
        position: relative;
        cursor: pointer;
        height: 100%;
        line-height: 80px;
        color: #000;
        z-index: 999;
        .second-menu {
          display: none;
        }

        a {
          display: inline-block;
          width: 100%;
          height: 100%;
          line-height: 80px;
          color: #000;
          position: relative;
        }


      }

      .nav-active {
        vertical-align: top;
        color: #29d087!important;

        a {
          color: #29d087;

        }

        .under-line {
          position: absolute;
          left: 50%;
          margin-left: -10px;
          bottom:0px;
          width: 20px;
          height: 6px;
          border-radius: 20px;
          background: #29d087;
        }
      }

      // .nav:hover {
      //   vertical-align: top;
      //   margin-top: -1px;
      //   transform: scale(1.1);
      //   color: #29d087;

      //   a {
      //     color: #29d087;

      //   }

      //   .under-line {
      //     position: absolute;
      //     left: 50%;
      //     transform: translateX(-10px);
      //     bottom: 0px;
      //     margin: auto;
      //     width: 20px;
      //     height: 6px;
      //     border-radius: 20px;
      //     background: #29d087;
      //   }

      //   .second-menu {
      //     z-index: 999 !important;
      //     display: block;
      //     position: absolute;
      //     width: 120px;
      //     background: #FFFFFF;
      //     box-shadow: 0px 0px 16px 0px rgba(131, 131, 131, 0.08);
      //     border-radius: 12px;
      //     text-align: center;
      //     background: #fff;
      //     left: 50%;
      //     margin-left: -60px;
      //     top: 78px;

      //     .menu-list {
      //       box-sizing: border-box;
      //       padding: 4px;
      //       .menu-item {
      //         display: block;
      //         box-sizing: border-box;
      //         padding: 0 16px;
      //         width: 112px;
      //         height: 40px;
      //         line-height: 40px;
      //         text-align: left;
      //         color: #333333;
      //         font-size: 16px;
      //       }

      //       .menu-item:hover {
      //         background: #F4F4F4;
      //         border-radius: 12px;
      //         color: #29d087;
      //         position: relative;

      //         .sign {
      //           position: absolute;
      //           left: 0;
      //           top: 14px;
      //           width: 6px;
      //           height: 12px;
      //           background: #29D087;
      //           border-radius: 3px;
      //         }

      //       }

      //       .menu-item-active {
      //         background: #F4F4F4;
      //         border-radius: 12px;
      //         color: #29d087;
      //         position: relative;

      //         .sign {
      //           position: absolute;
      //           left: 0;
      //           top: 14px;
      //           width: 6px;
      //           height: 12px;
      //           background: #29D087;
      //           border-radius: 3px;
      //         }

      //       }
      //     }
      //   }
      // }
    }
  }

</style>
