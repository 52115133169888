import { formatMoment } from '@/utils/utils'
import Calendar from '@/components/CalendarS/Calendar.vue'
import * as Api from '@/api/index/index'
import moment from 'moment'

export default {
    name: 'ActivitySchedule',
    data() {
        return {
            activityList: [],
            extraActivityList: [],
            currentMonthData: [],
            nextMonthData: [],
            currentActivity: {},
            currentIndex: 0,
            interVal: null,
            canToPrev: false,
            canToNext: false

        }
    },
    props: {
        categoryId: {
            type: [Number, String],
            default: ''
        }
    },
    mounted() {
        const start = moment().startOf('month').format('YYYY-MM-DD')
        const end = moment().add(1, 'month').endOf('month').format('YYYY-MM-DD')
        this.getCalendarData(start, end, this.categoryId)
        this.changeCurrentActivity()
    },
    methods: {
        getCalendarData(start, end, categoryId) {
            const params = {
                started_at: start,
                ended_at: end,
                category_id: categoryId
            }
            let currentMonth = moment().startOf('month').format('MM')
            let nextMonth = moment().add(1, 'month').format('MM')
            let currentMonthData = []
            let nextMonthData = []
            Api.activityCalendarListApi(params, res => {
                res.forEach(course => {
                    if (moment(course.started_at * 1000).format('MM') === currentMonth) {
                        currentMonthData.push(course)
                    }
                    if (moment(course.started_at * 1000).format('MM') === nextMonth) {
                        nextMonthData.push(course)
                    }
                })
                if (nextMonthData.length > 0) this.canToNext = true
                this.currentMonthData = currentMonthData
                this.nextMonthData = nextMonthData
                this.activityList = currentMonthData
                if(this.activityList.length<3){
                    for( let i = 0; i< 3-this.activityList.length; i++){
                        this.extraActivityList.push(i)
                    }
                }
                this.currentActivity = this.activityList[0]
            })
        },
        handleChooseActivity(item, index) {
            this.currentActivity = item
            this.currentIndex = index

        },
        changeCurrentActivity() {
            let index = this.currentIndex
            let self = this
            this.interVal = setInterval(() => {
                if (index > this.activityList.length - 1) {
                    index = 0
                    if(self.$refs.listViewRef) {
                        self.$refs.listViewRef.scrollTop = 0
                    }
                }
                if (index % 3 === 0) {
                    if(self.$refs.listViewRef) {
                        self.$refs.listViewRef.scrollTop = 405 * (index / 3)
                    }
                }
                this.currentActivity = this.activityList[index]
                index++
            }, 3000)
        },
        handleMouseOver() {
            if (this.interVal) {
                clearInterval(this.interVal)
            }
        },
        handleMouseLeave() {
            this.changeCurrentActivity()
        },
        changeView(direction) {
            if (direction === 'next') {
                this.activityList = this.nextMonthData
                this.canToNext = false
                this.canToPrev = true
            }
            if (direction === 'prev') {
                this.activityList = this.currentMonthData
                this.canToPrev = false
                this.canToNext = true
            }
        },
        changeActivity(data) {
            this.handleChooseActivity(data, data.index)
        },
        formatMoment,
        // 跳转公开课详情
        toOpenCourse() {
            this.$router.push({
                path: `/activityDetail/${this.currentActivity.id}`
            })
        }
    },
    destroyed() {
        clearInterval(this.interVal)
    },
    watch: {
        'categoryId' (newId) {
            console.log(newId)
            if (newId) {
                const start = moment().startOf('month').format('YYYY-MM-DD')
                const end = moment().add(1, 'month').endOf('month').format('YYYY-MM-DD')
                this.getCalendarData(start, end, newId)
            }
        }

    },
    components: { Calendar },
}
