import Fetch from '@/utils/fetch.js'

/**
 * 广告位
 * @param {object} data
 * @param {adsPosition} data.position
 * */

const ads = ( data, fn, errorFn, dataLevel ) => {
  new Fetch('ads', 'get', data, fn, errorFn, dataLevel)
}

// 获取分类
const categoryType = ( fn, errorFn, dataLevel ) => {
  new Fetch('categories', 'get', '', fn, errorFn, dataLevel)
}

// 获取首页推荐分类
const categoryTypeRecommend = ( fn, errorFn, dataLevel ) => {
  new Fetch('categories/exists_list', 'get', '', fn, errorFn, dataLevel)
}

/**
 *  课程统计
 *  @param {number} course_id
 *  @param {object} data
 *  @param {number} [data.duration] -
 *  @param {ANALYTIC_TYPE} [data.type]
 *
 * */
const courseAnalystic = ( course_id, data, fn, errorFn, dataLevel ) => {
  new Fetch(
      `courses/${course_id}/analytic`,
      'post',
      data,
      fn,
      errorFn,
      dataLevel
  )
}

// 我的课程视频播放次数统计
const courseWatch = ( course_schedule_id, fn, errorFn, dataLevel = 'v1' ) => {
  new Fetch(
      `course_plans/classes/schedules/${course_schedule_id}/play`,
      'post',
      '',
      fn,
      errorFn,
      dataLevel
  )
}
// 我的课程班课视频播放时长统计
const classesCourseWatchDuration = (
    course_schedule_id,
    data,
    fn,
    errorFn,
    dataLevel = 'v2'
) => {
  new Fetch(
      `course_plans/classes/schedules/${course_schedule_id}/watch`,
      'post',
      data,
      fn,
      errorFn,
      dataLevel
  )
}

// 我的课程视频播放时长统计
const courseWatchDuration = (
    courseId,
    scheduleId,
    data,
    fn,
    errorFn,
    dataLevel = 'v2'
) => {
  new Fetch(
      `courses/${courseId}/schedules/${scheduleId}/watch`,
      'post',
      data,
      fn,
      errorFn,
      dataLevel
  )
}

const coursePlay = ( courseId, scheduleId, fn, errorFn, dataLevel = 'v1' ) => {
  new Fetch(
      `courses/${courseId}/schedules/${scheduleId}/play`,
      'post',
      '',
      fn,
      errorFn,
      dataLevel
  )
}

/**
 *  活动统计
 *  @param {number} community_group_id
 *  @param {object} data
 *  @param {number} [data.duration] -
 *  @param {ANALYTIC_TYPE} [data.type]
 *
 * */
const communityStay = ( community_group_id, data, fn, errorFn, dataLevel ) => {
  new Fetch(
      `activity/community_groups/${community_group_id}/analytic`,
      'post',
      data,
      fn,
      errorFn,
      dataLevel
  )
}

/**
 *  资料中心统计
 *  @param {number} community_group_id
 *  @param {object} data
 *  @param {number} [data.duration] -
 *  @param {ANALYTIC_TYPE} [data.type]
 *
 * */
const materialAnalystic = ( material_id, data, fn, errorFn, dataLevel ) => {
  new Fetch(
      `activity/materials/${material_id}/analytic`,
      'post',
      data,
      fn,
      errorFn,
      dataLevel
  )
}

// 资料停留时间

// const materialStay = (data, fn, errorFn, dataLevel) => {
//     new Fetch('analytics/material/stay', 'post', data, fn, errorFn, dataLevel)
// }

// 资料点击统计

const materialConsult = ( data, fn, errorFn, dataLevel ) => {
  new Fetch('analytics/material/consult', 'post', data, fn, errorFn, dataLevel)
}

// 资料报名
const materialRecord = ( material_id, fn, errorFn, dataLevel ) => {
  new Fetch(
      `activity/materials/${material_id}/records`,
      'post',
      '',
      fn,
      errorFn,
      dataLevel
  )
}

// 抢先听次数统计

const trialPlayTimes = ( data, fn, errorFn, dataLevel ) => {
  new Fetch(
      'analytics/course_schedule/play',
      'post',
      data,
      fn,
      errorFn,
      dataLevel
  )
}

//抢先听时长统计

const trialPlayDuration = ( data, fn, errorFn, dataLevel ) => {
  new Fetch(
      'analytics/course_schedule/watch',
      'post',
      data,
      fn,
      errorFn,
      dataLevel
  )
}

// linkPage invite
// 提交微信号

const submitWechat = ( data, fn, errorFn, dataLevel ) => {
  new Fetch('promote/promoter_customers', 'post', data, fn, errorFn, dataLevel)
}
const configInit = ( data, fn, errorFn, dataLevel ) => {
  new Fetch('config/init', 'get', data, fn, errorFn, dataLevel)
}

// 教师列表
const teacherList = ( data, fn, errorFn, dataLevel ) => {
  new Fetch('teachers', 'get', data, fn, errorFn, dataLevel)
}

// 搜索
const search = ( data, fn, errorFn, dataLevel ) => {
  new Fetch('search/homepage', 'get', data, fn, errorFn, 'v2')
}

// 搜索关键词
const searchWordList = ( data, fn, errorFn, dataLevel ) => {
  new Fetch('search/recommend_keywords', 'get', data, fn, errorFn, 'v2')
}


export {
  ads,
  categoryType,
  courseAnalystic,
  courseWatch,
  communityStay,
  materialAnalystic,
  materialConsult,
  trialPlayTimes,
  trialPlayDuration,
  courseWatchDuration,
  submitWechat,
  configInit,
  classesCourseWatchDuration,
  categoryTypeRecommend,
  materialRecord,
  coursePlay,
  teacherList,
  searchWordList,
  search
}
