const mediaData = [{
  href: 'http://dy.163.com/v2/article/detail/F1M20HUM0519CMT4.html',
  img: require('./img/wy.jpg'),
},
  {
    href: 'https://www.sohu.com/a/363744684_537750',
    img: require('./img/sh.jpg'),
  },

  {
    href: 'http://www.chinaedunews.net/a/201908/10052.html',
    img: require('./img/zhjy.jpg'),
  },
  {
    href: 'http://www.zgjyol.cn/jynews/2019/2944286908.html',
    img: require('./img/zgjy.pic.jpg'),
  },

  {
    href: 'http://www.hxedu.org/news/12140.html',
    img: require('./img/hxjy.jpg'),
  },
  {
    href: 'http://chinatcedu.cn/xw/158.html',
    img: require('./img/dfjy.jpg'),
  },
  {
    href: 'http://www.huaxun.org/zx/2019-08-29/378284.html',
    img: require('./img/hxw.jpg'),
  },
  {

  }
];

export { mediaData };
