import ActivitySchedule from '@/modules/ActivitySchedule/ActivitySchedule.vue'
import SeriesAndSup from '@/views/Index/comonents/seriesAndSup.vue'
import Teacher from '@/components/Teacher/Teacher.vue'
import MenuList from './comonents/menu.vue'
import bannerSwiper from './comonents/swiper.vue'
import MaterialAndArticle from '@/views/Index/comonents/materialAndArticle.vue'
import scoreSwiper from '@/views/Index/comonents/scoreSwiper.vue'
import adList from '@/views/Index/comonents/adList.vue'
import FloatBar from '@/components/FloatBar/FloatBar.vue'
import FloatAds from '@/components/FloatAds/FloatAds.vue'
import { mediaData } from '@/views/Index/data'
import { ads } from '@/api/common/common'
import * as Api from '@/api/index/index'
import { mapState } from 'vuex'
import { adsPosition } from '@/enums/API'
import { formatMoment, jumpPage, resolveAddress } from '@/utils/utils'
import AdDialog from '@/components/AdDialog/AdDialog.vue'
import { indexLazyLoad } from '@/views/Index/indexLazyLoad'

export default {
  mixins: [indexLazyLoad],
  name: 'Index',
  data () {
    return {

      tuofuCategory: 'TOEFL',
      isShowQrcode: false,
      categoryData: [],
      qrImage: '',
      articleSwiperData: {},
      activeScoreSwipeCategory: 'IELTS',
      dataRefreshAt: 0,
      mediaData,
      bannerData: [],
      adsData: {},
      adsPosition,
      adDialogConfig: {
        type: adsPosition.OfficialWebsitePopUp,
        config: {
          image_url: '',
          link_url: ''
        }
      },
      otherAdsPosition: [
        adsPosition.MOCK_EXAM,
        adsPosition.MAIN_BANNER,
        adsPosition.SIDE_BANNER,
        adsPosition.HOMEPAGE_BOTTOM,
        adsPosition.MATERIAL_SPEAKING,
        adsPosition.FLOAT_LEFT,
        adsPosition.OfficialWebsitePopUp
      ],
      apiBeginTime: 0
    }
  },
  beforeCreate () {
    this.apiBeginTime = performance.now()
  },
  async mounted () {
    await this.init()
    await this.getData()
    this.lazyLoad()
  },
  computed: {
    ...mapState({
      categoryList: state => state.common.categoryTypes
    }),

    adsDataBanner () {
      return this.adsData[ adsPosition.SIDE_BANNER ] ? this.adsData[ adsPosition.SIDE_BANNER ].slice(0, 2) : []
    }
  },

  methods: {
    async init () {
      const { refreshed_at } = await this.getRefresh()
      console.log('refreshed_at', refreshed_at)
      if (localStorage.lastGetDataTime && localStorage.articleSwiperData && localStorage.adsData) {
        const lastGetDataTime = localStorage.lastGetDataTime
        if (refreshed_at <= lastGetDataTime) {
          this.getCache()
        }
      }
    },
   async getData () {
      const lastGetDataTime = localStorage.lastGetDataTime  || 0
      if (this.dataRefreshAt !== lastGetDataTime) {
       await this.getArticles({ id: 1, abbr: 'IELTS' })
      }
    },
    getCache () {
      this.adsData = JSON.parse(localStorage.adsData)
      this.articleSwiperData = JSON.parse(localStorage.articleSwiperData)
    },
    setCache ( key, value ) {
      localStorage.setItem(key, JSON.stringify(value))
    },
    getAdsCallback ( adsObj ) {
      this.adsData = adsObj
      this.adDialogConfig.config = adsObj[ adsPosition.OfficialWebsitePopUp ][ 0 ]
    },
    async getArticles ( category ) {
      let params = {
        article_type_id: 1, // 出分类型的文章
        category_id: category.id
      }

     await Api.articleParameter(params, ( res ) => {
        this.$set(this.articleSwiperData, category.abbr, res)
        this.setCache('articleSwiperData', this.articleSwiperData)
      })
    },
    handleToggleScoreSwiper ( category ) {
      this.activeScoreSwipeCategory = category.abbr
      if (this.articleSwiperData[ category.abbr ]) {
      } else {
        this.getArticles(category)
      }

    },
    getRefresh () {
      return new Promise(( resolve, reject ) => {
        Api.configRefresh(res => {
          sessionStorage.refreshedAt = res.refreshed_at
          this.dataRefreshAt = res.refreshed_at

          resolve(res)
        }, ( err ) => {
          reject(err)
        })
      })

    },
    turnPageTo ( item ) {
      _hmt.push(['_trackEvent', 'home_page_ads', 'click', item.title])
      if (item.address !== '') {
        if (item.address.indexOf('http') > -1) {
          window.open(item.address)
        } else {
          window.location.href = item.address
        }
      }
    },
    openPage ( url ) {
      jumpPage(url)
    },
    callService () {
      this.categoryData = this.$store.state.common.categoryTypes
      this.categoryData.forEach(item => {
        if (item.abbr === this.activeScoreSwipeCategory) {
          this.isShowQrcode = true
          this.qrImage = item.qrcode_image_url
        }
      })
    },
    delQrcodeBox () {
      this.isShowQrcode = false
    }
  },
  components: { FloatAds, ActivitySchedule, Teacher, bannerSwiper, MenuList, scoreSwiper, adList, MaterialAndArticle, SeriesAndSup, FloatBar, AdDialog },
}
