<template>
  <div class="swiper swiper-container">
    <swiper ref="mySwiper" :options="swiperOptions"
            @mouseenter.native.stop="mouseOverSlider"
            @mouseleave.native.stop="mouseLeaveSlider"
    >
      <swiper-slide v-for="(slide, index) in bannerList" :key="index">
        <div class="image-box" @click="turnPageTo(slide)">
          <img v-lazy="slide.image_url" alt="">
        </div>
      </swiper-slide>
      <div
        class="swiper-button-prev swiper-button-white"
        @mouseenter.stop="mouseOverLeft"
        :class="swiperBtn ? 'show' : 'hide'"
        slot="button-prev"
      >
      </div>
      <div
        class="swiper-button-next swiper-button-white"
        :class="swiperBtn ? 'show' : 'hide'"
        slot="button-next">
      </div>
    </swiper>
    <div class="custom-handler">
      <template v-for="(group, index) in titleList">
        <div class="container" v-if="activeGroup === group[0].id" :key="index">
          <div class="item" v-for="title in group" :key="title.id"
               :class="activeId === title.id?'item-active':'' "
               @click="handleClickSlide(title)"
          >
            {{ title.title }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import { resolveAddress } from '@/utils/utils'

  export default {
    name: 'papaSwiper',
    data () {
      return {
        list: [],
        titleList: [],
        activeId: '',
        activeGroup: '',
        swiperBtn: false,
        swiperOptions: {
          autoplay: false,
          loop: false,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        },
        swiper: null
      }
    },
    props: {
      swiperData: {
        type: Array,
        default: () => {
          return []
        }
      }
    },

    mounted () {
      this.$nextTick(() => {
        this.swiper = this.$refs.mySwiper.$swiper
        this.swiperEvent()

      })
    },
    computed: {
      bannerList () {
        let data = []
        this.swiperData.forEach(( ele ) => {
          ele.address = resolveAddress(ele)
          let obj = { ...ele }
          data.push(obj)
        })

        return data
      },
    },
    methods: {
      mouseOverSlider () {
        this.swiperBtn = true
        this.swiper && this.swiper.autoplay.stop()
      },
      mouseLeaveSlider () {
        this.swiperBtn = false
        this.swiper && this.swiper.autoplay.start()
      },
      mouseOverLeft () {
      },
      turnPageTo ( item ) {
        _hmt.push(['_trackEvent', 'home_page_ads', 'click', item.title])
        if (item.address !== '') {
          if (item.address.indexOf('http') > -1) {
            window.open(item.address)
          } else {
            window.location.href = item.address
          }
        }
      },
      handleClickSlide ( slide ) {
        this.swiper.slideTo(slide.index)
      },
      swiperEvent () {
        this.$refs.mySwiper.$on('slideChangeTransitionStart', () => {
          if (this.swiper) {
            const index = this.swiper.activeIndex
            this.activeId = this.bannerList[ index ].id
            if (index % 4 === 0) {
              this.activeGroup = this.titleList[ index / 4 ][ 0 ].id
            } else {
              this.activeGroup = this.titleList[ Math.floor(index / 4) ][ 0 ].id
            }
          }
        })
      }
    },
    watch: {
      bannerList: {
        deep: true,
        handler ( newData ) {
          newData.forEach(( item, index ) => {
            item.index = index
            if (index === 0) {
              this.activeId = item.id
              this.activeGroup = item.id
            }
          })
          this.titleList = []
          for (let i = 0; i < newData.length; i += 4) {
            this.titleList.push(newData.slice(i, i + 4))
          }
        }
      }
    },
  }
</script>

<style scoped lang="less">
  .image-box {
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
    }
    img[lazy=loading] {
      width: 60px;
    }
  }

  ::v-deep .swiper-button-next {
    margin-right: 15px;
  }

  .swiper-container {
    height: inherit;
  }

  .custom-handler {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 40px;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(204, 204, 204);

    .handle-button {
      width: 40px
    }

    & > .container {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > .item {
        flex: 1;
        position: relative;
        font-size: 14px;
        height: 40px;
        line-height: 40px;
        cursor: pointer;
      }

      & > .item::after {
        position: absolute;
        right: 0;
        top: 10px;
        content: '';
        width: 1px;
        height: 20px;
        background: rgba(122, 120, 120, 0.507);
      }

      & > .item:last-child::after {
        content: '';
        width: 0;
        height: 0
      }

      & > .item-active {
        background: #29d087;
        color: #fff;
        margin-left: -1px;
      }

      & > .item-active::after {
        content: '';
        width: 0;
        height: 0
      }
    }

  }

  .hide {
    display: none;
  }

  .swiper-button-next,
  .swiper-container-rtl, .swiper-button-prev {
    top: 154px;
    right: 0px;
    color: #fff;
    text-align: left;
    width: 40px;
    height: 40px;
    background: rgba(0, 0, 0, 0.2);
    text-align: center;
    line-height: 80px;
    border-radius: 50%;

  }

  .swiper-button-white:after {
    font-size: 16px;
    font-weight: bold;
  }

  .swiper-button-next:hover, .swiper-button-prev:hover {
    color: #29d087;
  }
</style>
