import Fetch from '@/utils/fetch.js'
import { ads, categoryType, categoryTypeRecommend } from '@/api/common/common'
import { course, courseRecommend } from '@/api/course/course'

/**
 *公益课
 * 直播课、训练营
 * @param {activityType} type
 * */
const activityCommunityGroups = (type, data, fn, errorFn, dataLevel) => {
    new Fetch(
        `activity/community_groups/${type}/recommend`,
        'get',
        data,
        fn,
        errorFn,
        dataLevel
    )
}

const activityCommunityGroupsAll = (type, data, fn, errorFn, dataLevel) => {
  new Fetch(
      `activity/community_groups/${type}`,
      'get',
      data,
      fn,
      errorFn,
      dataLevel
  )
}

/**
 * 公益课分类
 * @param {activityType} activityType
 * */
const activityCategory = (activityType, fn, errorFn, dataLevel) => {
    new Fetch(`categories/activities/community_group/${activityType}`,
        'get',
        '',
        fn,
        errorFn,
        dataLevel
    )
}

//资料中心
const courseMaterial = (data, fn, errorFn, dataLevel, id = '') => {
    new Fetch(
        `activity/materials/${id}`,
        'get',
        data,
        fn,
        errorFn,
        dataLevel
    )
}

// 资料中心分类
const courseMaterialCategory = (data, fn, errorFn, dataLevel) => {
    new Fetch('categories/activities/material',
        'get',
        data,
        fn,
        errorFn,
        dataLevel
    )
}


//学员出分
const articleParameter = (data, fn, id = '', errorFn, dataLevel) => {
    new Fetch(`articles/${id}`, 'get', data, fn, errorFn, dataLevel)
}

//精品文章分类
const articleSorts = (data, fn, errorFn, dataLevel) => {
    new Fetch('articles/filter', 'get', data, fn, errorFn, dataLevel)
}


/****************   改版  ****************/
// 分类课程主题
const categoryTopicApi = (categoryId, fn, errorFn, dataLevel) => {
    new Fetch(`mall/categories/${categoryId}/topic`, 'get', '', fn, errorFn, dataLevel)
}


// 主题系列
const topicSeriesApi = (categoryId, fn, errorFn, dataLevel) => {
    new Fetch(`mall/categories/${categoryId}/series`, 'get', '', fn, errorFn, dataLevel)
}

//spu
const seriesSpuApi = (params, fn, errorFn, dataLevel) => {
    new Fetch('mall/series/spus', 'get', params, fn, errorFn, dataLevel)
}

//spuDetail
const spuDetailApi = (spuId, fn, errorFn, dataLevel) => {
    new Fetch(`mall/spus/${spuId}`, 'get', '', fn, errorFn, dataLevel)
}

// sku
const skuDetailApi = (skuId, fn, errorFn, dataLevel) => {
    new Fetch(`mall/skus/${skuId}`, 'get', '', fn, errorFn, dataLevel)
}

//course List
const courseSyllabusApi = (data, fn, errorFn, dataLevel) => {
    new Fetch('course_syllabus', 'get', data, fn, errorFn, dataLevel)
}

// menu

const menuListApi = (fn, errorFn, dataLevel) => {
    new Fetch('menus', 'get', '', fn, errorFn, dataLevel)
}


//活动日历
const activityCalendarListApi = (data, fn, errorFn, dataLevel) => {
    new Fetch('activity/community_groups/calendar', 'get', data, fn, errorFn, dataLevel)
}


//推荐资料
const materialRecommend = (data, fn, errorFn, dataLevel) => {
    new Fetch('activity/materials/recommend', 'get', data, fn, errorFn, dataLevel)
}

//推荐文章
const articleRecommend = (data, fn, errorFn, dataLevel) => {
    new Fetch('articles/recommend', 'get', data, fn, errorFn, dataLevel)
}

//推荐 spu
const spuRecommend = (categoryId, fn, errorFn, dataLevel) => {
    new Fetch('mall/homepage/series', 'get', { category_id: categoryId }, fn, errorFn, dataLevel)
}


// 分类课程优选
const caregorySeries = (categoryId, fn, errorFn, dataLevel) => {
    new Fetch('mall/category/series', 'get', { category_id: categoryId }, fn, errorFn, dataLevel)
}

// 课程老师
const courseTeachers = (courseIds, fn, errorFn, dataLevel) => {
        new Fetch('course_teachers', 'get', { course_ids: courseIds }, fn, errorFn, dataLevel)
    }
    // 特别加赠
const spuLargess = (spuId, fn, errorFn, dataLevel) => {
    new Fetch(`mall/spus/${spuId}/largess`, 'get', '', fn, errorFn, dataLevel)
}

// 刷新缓存
const configRefresh = (fn, errorFn, dataLevel) => {
    return new Fetch('config/refreshed', 'get', '', fn, errorFn, dataLevel)
}

// 系列下方广告位
const seriesAds = (seriesId, fn, errorFn, dataLevel) => {
    return new Fetch(`mall/series/${seriesId}/ads`, 'get', '', fn, errorFn, dataLevel)
}

//获取腾讯云的token
const tencentToken = (type, params = {}, fn, errorFn, dataLevel) => {
    return new Fetch(`upload/${type}/token`, 'get', params, fn, errorFn, dataLevel)
  }


export {
    ads,
    course,
    courseRecommend,
    categoryType,
    activityCategory,
    activityCommunityGroups,
    courseMaterial,
    courseMaterialCategory,
    articleParameter,
    articleSorts,
    categoryTypeRecommend,
    categoryTopicApi,
    topicSeriesApi,
    seriesSpuApi,
    spuDetailApi,
    skuDetailApi,
    courseSyllabusApi,
    menuListApi,
    activityCalendarListApi,
    materialRecommend,
    spuRecommend,
    articleRecommend,
    caregorySeries,
    courseTeachers,
    configRefresh,
    seriesAds,
    spuLargess,
  activityCommunityGroupsAll,
  tencentToken

}
