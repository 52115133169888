<script>
  import { adsPosition } from '@/enums/API'
  import { ads } from '@/api/common/common'
  import { configRefresh } from '@/api/index/index'
  import { formatMoment, resolveAddress } from '@/utils/utils'


  export default {
    data () {
      return {
        adsData: '',
        floatAdsBottom: '',
        dataRefreshAt:''
      }
    },
    props:{
      otherAdsPosition:{
        type: Array,
        default:()=> []
      },
      unAutoAcquire:{
        type: Boolean,
        default: false
      },
      category_id:{
        type: Number,
        default : 0
      }
    },
    async mounted () {
      if(!this.unAutoAcquire) this.init()
    },
    methods: {
      async init(){
        let floatAds
        let position = [...new Set(this.otherAdsPosition), adsPosition.FLOAT_LEFT_BOTTOM]
        switch (this.category_id){
          case 0:
            //首页 广告，采用强缓存机制
            const  refreshed_at  = this.dataRefreshAt = sessionStorage.refreshedAt
            const lastGetDataTime = localStorage.lastGetDataTime
            floatAds = JSON.parse(localStorage.getItem('FLOATADS') || '{}')
            if ( Number(refreshed_at) > Number(lastGetDataTime) || Object.keys(floatAds).length === 0 || !position.every(pos => floatAds.hasOwnProperty(pos))){
                //如果配置更新时间比本地配置存储时间要新、本地暂无广告缓存配置的时候、本地缓存的数据不全的时候，就通过接口重新请求数据
                this.getAds( position, this.category_id)
              return
            }
            this.$emit('adsRes', floatAds)
            this.adsData = floatAds[ adsPosition.FLOAT_LEFT ][ 0 ] || {}
            this.floatAdsBottom = floatAds[ adsPosition.FLOAT_LEFT_BOTTOM ][ 0 ] || {}

            break;
          default:
            //分类广告内容，采用弱缓存机制
            floatAds = JSON.parse(sessionStorage.FLOATADSCLASSIFY || '{}')[this.category_id] || {}
            if (Object.keys(floatAds).length === 0 || !position.every(pos => floatAds.hasOwnProperty(pos))){
                //本地暂无广告缓存配置的时候、本地缓存的数据不全的时候，就通过接口重新请求数据
                try {
                  await this.getAds(position, this.category_id);
                  //左侧浮动广告位是 公共广告，需要单独请求
                  await this.getAds([adsPosition.FLOAT_LEFT], 0);
                } catch (error) {
                  console.error('Error fetching ads:', error);
                }
              return
            }
            this.$emit('adsRes', floatAds)
            this.adsData = floatAds[ adsPosition.FLOAT_LEFT ][ 0 ] || {}
            this.floatAdsBottom = floatAds[ adsPosition.FLOAT_LEFT_BOTTOM ][ 0 ] || {}

        }


      },
      async getAds(position, category_id) {
        return new Promise((resolve, reject) => {
          ads({ position, category_id }, res => {
            try {
              localStorage.lastGetDataTime = formatMoment(new Date() / 1000, 'YYYYMMDDHHmmss')
              let adsObj = {}
              res.forEach(item => {
                item.address = resolveAddress(item)
                if (adsObj[item.position]) {
                  adsObj[item.position].push(item)
                } else {
                  adsObj[item.position] = []
                  adsObj[item.position].push(item)
                }
              })
              position.map(item => {
                if (!adsObj[item]) adsObj[item] = []
              })

              switch (this.category_id) {
                case 0:
                this.$emit('adsRes', adsObj)
                  this.adsData = adsObj[adsPosition.FLOAT_LEFT][0]
                  localStorage.setItem('FLOATADS', JSON.stringify(adsObj))
                  break;
                default:
                  if(category_id) this.$emit('adsRes', adsObj)
                  if(!category_id) this.adsData = adsObj[adsPosition.FLOAT_LEFT][0]
                  let floatAds = JSON.parse(sessionStorage.FLOATADSCLASSIFY || '{}') || {}
                  floatAds[this.category_id] = floatAds[this.category_id] || {}
                  floatAds[this.category_id] = category_id ? adsObj : Object.assign(floatAds[this.category_id], adsObj)
                  this.floatAdsBottom = floatAds[this.category_id][ adsPosition.FLOAT_LEFT_BOTTOM ][ 0 ] || {}

                  sessionStorage.FLOATADSCLASSIFY = JSON.stringify(floatAds)
              }
              resolve()
            } catch (error) {
              reject(error)
            }
          })
        })
      },
      turnPageTo () {
        const item = this.adsData
        _hmt.push(['_trackEvent', 'home_page_ads', 'click', item.title])
        if (item.address !== '') {
          if (item.address.indexOf('http') > -1) {
            window.open(item.address)
          } else {
            window.location.href = item.address
          }
        }
      },
      bottomTurnPageTo () {
        const item = this.floatAdsBottom
        _hmt.push(['_trackEvent', 'home_page_ads', 'click', item.title])
        if (item.address !== '') {
          if (item.address.indexOf('http') > -1) {
            window.open(item.address)
          } else {
            window.location.href = item.address
          }
        }
      },
    }
  }
</script>

<template>
  <div class="left-ads">
    <div class="float-ads" v-if="adsData" @click="turnPageTo">
      <img :src="adsData.image_url" alt="">
    </div>
    <div class="float-ads-bottom" v-if="floatAdsBottom?.image_url" @click="bottomTurnPageTo">
      <img :src="floatAdsBottom.image_url" alt="">
    </div>
  </div>

</template>

<style scoped lang="less">
  @media screen and (max-width: 1400px) {
    .float-ads, .float-ads-bottom{
      margin-right: 43%!important;
    }
  }
  .float-ads {
    position: fixed;
    top: 100px;
    right: 50%;
    margin-right: 620px;
    width: 80px;
    animation: heart 1.3s ease-in-out infinite alternate;
    cursor: pointer;
    z-index: 9;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .float-ads-bottom{
    position: fixed;
    bottom: 100px;
    right: 50%;
    margin-right: 620px;
    width: 80px;
    cursor: pointer;
    z-index: 9999;

    img {
      width: 100%;
      height: 100%;
    }
  }

  @keyframes heart {
    from {
      transform: translate(0, 0)
    }
    to {
      transform: translate(0, 30px)
    }
    /* 下浮高度 */
  }
</style>
