let tic = null     // tic 实例
let roomID = ''   // 房间号
let isShowNotificationBox = false  // 通知面板
let msgs = []      // 消息列表
let userList = []  // 用户列表
let groupInfo = {} // 聊天室信息
let classStatus = sessionStorage.classStatus || 0      // 0 未开始  1 上课中  2 已结束
let micStatus = 2       // 1 可用状态  2 关闭状态  3 不用状态（浏览器不支持）
let cameraStatus = 0     // 1 开启状态 0关闭状态
let videoStatus = 0     // 1 开启状态  0关闭状态
let voiceStatus = 0    // 1 可用状态  0 静音  3 不用状态（浏览器不支持）
let shareStatus = 0     // 1 可用状态  0关闭状态
let videoWrap = 'video_wrap'
let currentUser = ''
let isMute = false
let isConnect = false
let handUpList = []
let connectList = []
let groupMute = 'Off'
let currentTeacher = {}

let liveMode = 1
let isVideoCall = false

let teduBoard = null // 白板实例
let uploadQuality = 0
let downloadQuality = 0
let isOnLone = false

export default {
  tic: tic,
  roomID: roomID,
  msgs: msgs,

  liveMode: liveMode,
  isVideoCall: isVideoCall,

  userList: userList,
  groupInfo: groupInfo,
  isShowNotificationBox: isShowNotificationBox,
  classStatus: classStatus,
  micStatus: micStatus,
  videoStatus: videoStatus,
  voiceStatus: voiceStatus,
  shareStatus: shareStatus,
  videoWrap: videoWrap,
  teduBoard: teduBoard,
  cameraStatus: cameraStatus,
  currentUser: currentUser,
  isMute: isMute,
  isConnect: isConnect,
  handUpList: handUpList,
  connectList: connectList,
  groupMute: groupMute,
  currentTeacher: currentTeacher,
  uploadQuality: uploadQuality,
  downloadQuality: downloadQuality,
  isOnLone: isOnLone
}
