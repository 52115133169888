import * as Types from './mutationTypes.js'

export default {
  [Types.SET_NEXT_MSG_SEQ](state, seq) {
    state.nextMsgSeq = seq
  },
  [Types.SET_IS_COMPLETED](state, status) {
    state.isCompleted = status
  },
  [Types.SET_TIM](state, tim) {
    state.tim = tim
  },
  [Types.SET_IM_SDK_STATUS](state, status) {
    state.isIMSdkReady = status
  }
}
