<template>
  <div class="material-article">
    <div class="section-material section-half" v-if="materialData.length > 0">
      <div class="section-head">
        <div class="section-title">精品备考资料</div>
        <router-link :to="`/${category}/material`" v-if="position ==='category'">查看更多
          <i class="iconfont icon-papa-coupon_arrow"></i>
        </router-link>
      </div>

      <div class="section-container">
        <div class="container-list">
          <router-link class="material-item"
               v-for="material in materialData"
               :key="material.id"
               :to="`/material/${material.id}`"
          >
            <div class="image-box">
              <img v-lazy="material.cover_image_url" alt="">
            </div>
            <div class="bottom">
              <div class="material-title">{{ material.title }}</div>
              <div class="material-desc">
                <div class="left">
                  已有{{ material.enroll_count }}人领取
                </div>
                <div class="right">免费</div>
              </div>
            </div>
          </router-link>
        </div>
        <a class="container-button" v-if="position==='index'" href="/ielts/material">
          获取更多备考资料
        </a>
      </div>
    </div>
    <div class="section-article section-half" v-if="articleData.length > 0">
      <div class="section-head">
        <div class="section-title">备考资讯中心</div>
        <router-link :to="`/${category}/article`" v-if="position ==='category'">查看更多
          <i class="iconfont icon-papa-coupon_arrow"></i>
        </router-link>
      </div>
      <div class="section-container">
        <div class="container-list">
          <router-link class="article-item" v-for="article in articleData" :key="article.id"
                       :to="`/article/${article.id}`">
            <span class="article-category">{{ article.category }}</span>
            <span class="article-type">{{ article.type }}</span>
            <span class="divided">|</span>
            <span class="article-title">{{ article.title }}</span>
          </router-link>
        </div>
        <div class="container-button" v-if="position==='index'" @click="callService">
          获取更多备考资讯
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import * as Api from '@/api/index/index.js'

  export default {
    name: 'materialAndArticle',
    data () {
      return {
        materialData: [],
        articleData: []
      }
    },
    props: {
      position: {   //  展示位置： index： 首页  category: 分类首页
        type: String,
        default: 'index'
      },
      category: {
        type: String,
        default: 'ielts'
      },
      categoryId: {
        type: Number,
        default: 1
      }
    },
    mounted () {

    },
    methods: {
      init(){
        this.getMaterial()
        this.getArticle()
      },
      getMaterial () {
        if(this.categoryId==0) return
        Api.materialRecommend({category_id: this.categoryId}, res => {
          this.materialData = res
        })
      },
      getArticle () {
        if(this.categoryId==0) return
        Api.articleRecommend({category_id: this.categoryId}, res => {
          this.articleData = res
        })
      },
      callService() {
        clickHandler()
      },
    },
    watch: {
      'categoryId'(newId){
        if(newId){
          this.getMaterial()
          this.getArticle()
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  .material-article {
    display: flex;
    justify-content: space-between;

    .section-half {
      .section-head {
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        a {
          font-size: 12px;
          color: #666;

          i {
            font-size: 12px;
          }
        }
      }

      .section-container {
        box-sizing: border-box;
        padding: 30px;
        width: 590px;
        background: #FFFFFF;
        border-radius: 28px;

        .container-button {
          display: block;
          width: 200px;
          height: 60px;
          background: #29d087;
          border-radius: 30px;
          margin: 0 auto;
          line-height: 60px;
          color: #fff;
          font-size: 16px;
          cursor: pointer;
          user-select: none;
        }
      }
    }

    & > .section-material {
      .container-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .material-item {
          box-sizing: border-box;
          width: 255px;
          height: 250px;
          border: 1px solid #F0F0F0;
          margin-bottom: 16px;
          border-radius: 8px;
          overflow: hidden;
          text-align: left;

          .image-box{
            display: flex;
            align-content: center;
            justify-content: center;
          }
          img {
            width: 255px;
            height: 145px;
          }

          & > .bottom {
            box-sizing: border-box;
            padding: 12px;

            .material-title {
              width: 100%;
              height: 50px;
              font-size: 16px;
              color: #000;
              font-weight: bold;
            }

            .material-desc {
              display: flex;
              justify-content: space-between;
              align-items: baseline;

              & > .left {
                font-size: 12px;
                color: #666666;
              }

              & > .right {
                color: #FF3333;
                font-size: 18px;
              }
            }
          }

        }
      }
    }

    & > .section-article {
      .container-list {
        text-align: left;
        overflow: auto;

        .article-item {
          box-sizing: border-box;
          display: block;
          margin-bottom: 18px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          cursor: pointer;
          font-size: 0px;
        }

        .article-category {
          width: 47px;
          height: 12px;
          padding: 3px;
          background: #667482;
          border-radius: 4px;
          display: inline-block;
          text-align: center;
          margin-right: 14px;
          color: #fff;
          font-size: 12px;
          line-height: 12px;
        }

        .article-type {
          display: inline-block;
          // width: 4.5em;
          color: #000;
          font-size: 14px;
        }

        .divided {
          margin: 0 10px;
          font-size: 14px;
        }

        .article-title {
          font-size: 14px;
          color: #333;
        }
      }
    }
  }
</style>
