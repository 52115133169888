let nextMsgSeq = ''
let msgList = []
let isCompleted = true
let tim = null
let isIMSdkReady = false

export default {
  nextMsgSeq: nextMsgSeq,
  msgList: msgList,
  isCompleted: isCompleted,
  tim: tim,
  isIMSdkReady: isIMSdkReady
}
