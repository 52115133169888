import * as Types from './moutationTypes'

export default {
  setTic: ({commit}, tic) => {
    commit(Types.SET_TIC, tic)
  },
  setRoomID: ({commit}, roomID) => {
    commit(Types.SET_ROOM_ID, roomID)
  },
  addMsg: ({commit}, msg) => {
    commit(Types.ADD_MSG, msg)
  },
  setUserList: ({commit}, list) => {
    commit(Types.SET_USER_LIST, list)
  },
  addUser: ({commit}, user) => {
    commit(Types.ADD_USER, user)
  },
  setGroupInfo: ({commit}, groupInfo) => {
    commit(Types.SET_GROUP_INFO, groupInfo)
  },
  setIsShowNotificationBox: ({commit}, status) => {
    commit(Types.SET_NOTIFICATION_STATUS, status)
  },
  setUserHandStatus: ({commit}, user) => {
    console.log(user)
    commit(Types.SET_USER_HAND_STATUS, user)
  },
  setUserConnectStatus: ({commit}, user) => {
    commit(Types.SET_USER_CONNECT_STATUS, user)
  },
  setClassStatus: ({commit}, status) => {
    commit(Types.SET_CLASS_STATUS, status)
  },
  setMicStatus: ({commit}, status) => {
    commit(Types.SET_MIC_STATUS, status)
  },
  setVideoStatus: ({commit}, status) => {
    commit(Types.SET_VIDEO_STATUS, status)
  },
  setVoiceStatus: ({commit}, status) => {
    commit(Types.SET_VOICE_STATUS, status)
  },
  setShareStatus: ({commit}, status) => {
    commit(Types.SET_SHARE_STATUS, status)
  },
  setVideoWrap: ({commit}, wrap) => {
    commit(Types.SET_VIDEO_WRAP, wrap)
  },
  setTeduBoard: ({commit}, teduBoard) => {
    commit(Types.SET_TEDUBOARD, teduBoard)
  },
  setCameraStatus: ({commit}, status) => {
    commit(Types.SET_CAMERA_STATUS, status)
  },
  setUserMute: ({commit}, status) => {
    commit(Types.SET_USER_MUTE, status)
  },
  setCurrentUser: ({commit}, account) => {
    commit(Types.SET_CURRENT_USER, account)
  },
  setIsConnect: ({commit}, status) => {
    commit(Types.SET_IS_CONNECT, status)
  },
  setHandUpList: ({commit}, list) => {
    console.log(list)
    commit(Types.SET_HAND_UP_LIST, list)
  },
  setConnectList: ({commit}, list) => {
    commit(Types.SET_CONNECT_LIST, list)
  },
  setGroupMute: ({commit}, status) => {
    commit(Types.SET_GROUP_MUTE, status)
  },
  setUserRole: ({commit}, user) => {
    commit(Types.SET_USER_ROLE, user)
  },
  setCurrentTeacher: ({commit}, teacher) => {
    commit(Types.SET_CURRENT_TEACHER, teacher)
  },
  clearTic: ({commit}) => {
    commit(Types.CLEAR_TIC)
  },
  clearLists: ({commit}) => {
    commit(Types.CLEAR_LISTS)
  },

  setLiveMode: ({commit}, liveMode) => {
    commit(Types.SET_LIVE_MODE, liveMode)
  },
  setIsVideoCall: ({commit}, status) => {
    commit(Types.SET_IS_SHOW_FACE, status)
  },
  addHistoryMsg: ({commit}, list) => {
    commit(Types.ADD_HISTORY_MSG, list)
  },
  setUploadQuality: ({commit}, quality) => {
    commit(Types.SET_UPLOAD_QUALITY, quality)
  },
  setDownloadQuality: ({commit}, quality) => {
    commit(Types.SET_DOWNLOAD_QUALITY, quality)
  },
  setIsOnLone: ({commit}, quality) => {
    commit(Types.SET_IS_ON_LONE, quality)
  }
}
