import {
  SET_CATEGORY_TYPE,
  SET_LOGIN_DIALOG,
  SET_GLOBAL_MASK,
  SET_INFORMATION_STATUS,
  SET_FIRST_PASSWORD
} from './mutation-types.js'
import { SET_GLOBAL_ERROR, SET_PERSONAL_DIALOG } from './mutation-types'

export default {
    [SET_CATEGORY_TYPE] (state, types) {
        state.categoryTypes = types
    },
    [SET_LOGIN_DIALOG] (state, status) {
        state.loginDialog = status
    },
    [SET_GLOBAL_MASK] (state, status) {
        state.globalMask = status
    },
    [SET_PERSONAL_DIALOG] (state, status) {
        state.personalMessageDialog = status
    },
    [SET_GLOBAL_ERROR] (state, error) {
        state.globalError = error
    },
    [SET_INFORMATION_STATUS] (state,status) {
        state.informationDialog = status
    },
  [SET_FIRST_PASSWORD] (state,status) {
      state.firstSetPasswordDialog = status
  }
}
