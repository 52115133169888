<template>
  <div class="c-calendar">
    <div class="calendar-title">
      <div class="leftBtn">
        <i v-show="canToPrev" class="iconfont icon-papa-rt" @click="handleChangePrev"></i>
      </div>
      <div class="center">
        <div class="con">
          {{ currentDay.format("YYYY年MM月") }}
        </div>
<!--        <i class="iconfont icon-papa-arrLeft"></i>-->
      </div>
      <div class="rightBtn">
        <i   v-show="canToNext" class="iconfont icon-papa-right" @click="handleChangeNext"> </i>
      </div>
    </div>
    <div class="calendar-content">
      <div class="content-head week">
        <div class="week-item" v-for="day in week" :key="day">周{{ day }}</div>
      </div>
      <div class="content-body">
        <div 
          class="day-item " 
          :class="[index === viewIndex ? 'day-item-pitch' : '', day.class ? 'calendarColor' : '', currentDay.format('YYYY-MM-DD') === day.fullDate && canToNext ? 'todaycolor' : '' ]" 
          v-for="(day,index) in viewDayList" 
          :key="day.fullDate"
          @click="handleChooseDate(day,index)"
        >
          <span>{{ day.date }}</span>
          <span v-if="day.lessons.length>0" class="day-item-sign" :class="index === viewIndex ? 'isShowCourse' : ''"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CalendarJs from "./Calendar.js";

export default CalendarJs;
</script>

<style scoped lang="less">
  .isShowCourse {
    background: transparent !important;
  }
  .todaycolor {
    width: 100%;
  height: 100%;
  border-radius: 69px 69px 69px 69px;
  background-color: #F5F5F5;
  color: #29d087;
  }
  .calendarColor {
    color: #ccc;
    pointer-events: none;
  }
 .day-item-pitch {
  width: 100%;
  height: 100%;
  border-radius: 69px 69px 69px 69px;
  background-color: #29d087;
  color: #fff;
  }
.c-calendar {
  border: 1px solid #e6e6e6;
  border-radius: 20px;
  height: 352px;

  & > .calendar-title {
    width: 100%;
    height: 36px;
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    align-items: center;
    justify-content: center;
    .leftBtn, .rightBtn{
      width: 20px;
    }
    i {
      width: 20px;
      color: #ccc;
      font-weight: bold;
      cursor: pointer;
    }
    .center {
      display: flex;
      align-items: center;
      margin: 0 40px;
      font-size: 14px;
      color: #000;
      font-weight: bold;
      .con {
        margin-right: 10px;
      }
    }
  }

  & > .calendar-content {
    .content-head {
      width: 100%;
      display: flex;
      font-size: 14px;
      color: #000;
      padding-bottom: 10px;
      padding-top: 30px;
      .week-item {
        text-align: center;
        flex: 1;
      }
    }

    .content-body {
      display: flex;
      flex-wrap: wrap;
      .day-item {
        cursor: pointer;
        flex: 1;
        position: relative;
        display: flex;
        justify-content: center;
        height: 45px;
        line-height: 45px;
        flex: 1 0 (100%/7);
        text-align: center;
        font-size: 16px;
        position: relative;

        .day-item-sign {
          position: absolute;
          top: 38px;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background: red;
        }
       
      }

      .day-item-active {
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;

        .day-item-con {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background: #29d087;
          text-align: center;
          line-height: 32px;
        }
      }
    }
  }
}
</style>
