import Fetch from '@/utils/fetch.js'
import Constant from '@/utils/constant'

// live token
const userSign = (fn, errorFn, dataLevel) => {
  new Fetch('im/sign', 'get', '', fn, errorFn, dataLevel)
}


// 直播详情
const getLiveDetail = (id, liveMode, fn, errorFn, dataLevel = 'v1') => {
  if (liveMode === Constant.LIVE_MODE.PRIVATE) {
    return new Fetch(`course_plans/personal/tasks/${id}`, 'get', '', fn, errorFn, dataLevel)
  } else if (liveMode === Constant.LIVE_MODE.TRAINING) {
    return new Fetch(`training/speaking/appointments/${id}/live`, 'get', '', fn, errorFn, 'v2')
  } else {
    return new Fetch(`user/course_plans/schedules/${id}`, 'get', '', fn, errorFn, dataLevel)
  }
}


// 是否可以进入教室
const canEnterLiveRoom = function (id, liveMode, fn, errofn, dataLevel = 'v1') {
  if (liveMode === Constant.LIVE_MODE.PRIVATE) {
    return new Fetch(`course_plans/personal/tasks/${id}/status`, 'get', '', fn, errofn, dataLevel)
  } else if (liveMode === Constant.LIVE_MODE.TRAINING) {
    return new Fetch(`training/speaking/appointments/${id}/live`, 'get', '', fn, errofn, 'v2')
  } else {
    return new Fetch(`course_plans/classes/schedules/${id}/status`, 'get', '', fn, errofn, dataLevel)
  }
}

// 预约类型
const getTypeApi = function (params, fn, errofn, dataLevel = 'v2') {
  new Fetch('training/config', 'get', params, fn, errofn, dataLevel)
}

// 直播配置id
const getLiveConfigApi = function (params, fn, errofn, dataLevel = 'v1') {
  new Fetch('config/live', 'get', params, fn, errofn, dataLevel)
}

// 退出房间
const exitRoom = function(id, fn, errofn, dataLevel = 'v1') {
  new Fetch(`training/speaking/appointments/${id}/exit_room`, 'post', '', fn, errofn, dataLevel)
}

// 加入房间
const joinRoom = function(id, data, fn, errofn, dataLevel = 'v1') {
  new Fetch(`training/speaking/appointments/${id}/join_room`, 'post', data, fn, errofn, dataLevel)
}
export {
  userSign,
  getLiveDetail,
  canEnterLiveRoom,
  getTypeApi,
  getLiveConfigApi,
  exitRoom,
  joinRoom
}
