import moment from 'moment'
import _ from 'loadsh'
import { formatMoment } from '@/utils/utils'

export default {
    name: 'Calendar',
    data() {
        return {
            week: ['一', '二', '三', '四', '五', '六', '日'],
            dayList: [],
            viewDayList: [],
            currentDay: moment(),
            viewIndex: '',
        }
    },
    props: {
        calendarData: {
            type: Array,
            default: () => {
                return []
            }
        },
        canToPrev: {
            type: Boolean,
            default: false
        },
        canToNext: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        const today = moment()
        this.produceDays(today)
    },
    methods: {
        produceDays(today, direction) {
            let monthDayList = []
            const currentYear = moment(today).format('YYYY')
            const days = today.daysInMonth()
            const _first = _.cloneDeep(today).startOf('month')
            const _last = _.cloneDeep(today).endOf('month')
            for (let i = 0; i < days; i++) {
                let d = _.cloneDeep(_first).add(i, 'days')
                monthDayList.push({
                    date: d.format('D') === '1' ? d.format('M') + '月' : d.format('D'),
                    fullDate: d.format('YYYY-MM-DD'),
                    lessons: [],
                    isRequest: false,
                })
            }

            let lenHead = _first.day() - 1 < 0 ? 6 : _first.day() - 1
            for (let i = 1; i <= lenHead; i++) {
                let d = _.cloneDeep(_first).add(-i, 'days')
                monthDayList.unshift({
                    date: d.format('D') === '1' ? d.format('M') + '月' : d.format('D'),
                    fullDate: d.format('YYYY-MM-DD'),
                    lessons: [],
                    class: 'preMonth',
                    isRequest: false,
                })
            }

            // 补月尾
            let lenTail = 7 - _last.day() //月尾天数
            for (let i = 1; i <= lenTail; i++) {
                let d = _.cloneDeep(_last).add(i, 'days')
                monthDayList.push({
                    date: d.format('D') === '1' ? d.format('M') + '月' : d.format('D'),
                    fullDate: d.format('YYYY-MM-DD'),
                    lessons: [],
                    class: 'nextMonth',
                    isRequest: false,
                })
            }
            if (direction === 'prev') {
                this.dayList.unshift(monthDayList)
            } else if (direction === 'next') {
                this.dayList.push(monthDayList)
            } else {
                this.dayList.push(monthDayList)
                this.viewDayList = this.dayList[0]
            }
            if (this.calendarData.length > 0) {
                this.renderData(this.calendarData)
            }
        },
        renderData(data) {
            this.viewDayList.forEach(day => {
                data.forEach((lesson, index) => {
                    if (formatMoment(lesson.started_at, 'YYYY-MM-DD') === day.fullDate) {
                        lesson.index = index
                        day.lessons.push(lesson)
                    }
                })
            })
        },
        handleChangeNext() {
            this.$emit('changeView', 'next')
            const today = moment().add(1, 'month')
            this.currentDay = today
            this.produceDays(today, 'next')
            this.viewDayList = this.dayList[1]
        },

        handleChangePrev() {
            this.currentDay = moment()
            this.viewDayList = this.dayList[0]
            this.$emit('changeView', 'prev')
        },
        handleChooseDate(data, index) {
            this.$emit('changeActivity', data.lessons[0])
            this.viewIndex = index
        }
    },
    watch: {
        calendarData: {
            handler(newData) {
                console.log(newData, 117)
                this.renderData(newData)
            },
            deep: true
        }
    }
}