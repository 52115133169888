import * as Types from './moutationTypes'
import {formatMoment} from '@/utils/utils'

export default {
  [Types.SET_TIC] (state, tic) {
    state.tic = tic
  },
  [Types.SET_ROOM_ID] (state, roomID) {
    state.roomID = roomID
  },
  [Types.ADD_MSG] (state, msg) {
    if(msg.length>0&&(msg.time - state.msgs[state.msgs.length-1].time> 300)){
      state.msgs.push({
        type: 'notify',
        content: formatMoment(msg.time,'HH:mm')
      })
    }
    state.msgs.push(msg)
  },
  [Types.SET_USER_LIST] (state, list) {
    let _list = []
    list.forEach(ele => {
      console.log(ele)
      if (ele.userID.indexOf('tic_record') < 0) {
        _list.push(ele)
      }
    })
    list = _list
    let listLength = list.length
    let localListLength = state.userList.length
    if (listLength > localListLength) {
      list.forEach(ele => {
        if (findUser(ele, state.userList) === '') {
          state.userList.push(ele)
        }

      })
    } else {
      let newList = []
      list.forEach(ele => {
        let index = findUser(ele, state.userList)
        if (index !== '') {
          newList.push(state.userList[index])
        }
      })
      state.userList = newList

      let _handList = []
      state.handUpList.forEach(ele => {
        let index = findUser(ele, state.userList)
        if (index !== '') {
          _handList.push(ele)
        }
      })
      state.handUpList = _handList
    }
  },
  [Types.ADD_USER] (state, user) {
    state.userList.push(user)
  },
  [Types.DEL_USER] (state, user) {
    state.userList.splice(findUser(user, state.userList), 1)
  },
  [Types.SET_GROUP_INFO] (state, groupInfo) {
    state.groupInfo = groupInfo
  },
  [Types.SET_NOTIFICATION_STATUS] (state, status) {
    state.isShowNotificationBox = status
  },
  [Types.SET_USER_HAND_STATUS] (state, user) {
    if (user.userID === 'none') {
      state.userList.forEach(ele => {
        ele.handUp = false
        // ele.isConnected = false
      })
      state.handUpList = []
    } else {
      let handIndex = findUser(user, state.handUpList)
      let userIndex = findUser(user, state.userList)
      let oldUser = state.userList[userIndex]
      let newUser = {...oldUser}
      newUser.handUp = user.handUp
      if (user.handUp) {
        state.handUpList.push(newUser)
      } else {
        state.handUpList.splice(handIndex, 1)
      }
      state.userList.splice(userIndex, 1, newUser)
    }
  },
  [Types.SET_USER_CONNECT_STATUS] (state, user) {
    if (user.userID === 'none') {
      state.userList.forEach(ele => {
        ele.isConnected = false
      })
    } else {
      let connectIndex = findUser(user, state.handUpList)
      let userIndex = findUser(user, state.userList)
      let oldUser = state.userList[userIndex]
      let newUser = {...oldUser}
      newUser.isConnected = user.isConnected
      if (user.isConnected) {
        state.handUpList.splice(connectIndex, 1, newUser)
      }
      state.userList.splice(userIndex, 1, newUser)
    }
  },
  [Types.SET_USER_ROLE] (state, user) {
    let userIndex = findUser(user, state.userList)
    let oldUser = state.userList[userIndex]
    let newUser = {...oldUser}
    newUser.Role = user.Role
    // newUser.identity = user.identity
    state.userList.splice(userIndex, 1, newUser)
  },
  [Types.SET_CLASS_STATUS] (state, status) {
    state.classStatus = status
  },
  [Types.SET_MIC_STATUS] (state, status) {
    state.micStatus = status
  },
  [Types.SET_VIDEO_STATUS] (state, status) {
    state.videoStatus = status
  },
  [Types.SET_VOICE_STATUS] (state, status) {
    state.voiceStatus = status
  },
  [Types.SET_SHARE_STATUS] (state, status) {
    state.shareStatus = status
  },
  [Types.SET_VIDEO_WRAP] (state, wrap) {
    state.videoWrap = wrap
  },
  [Types.SET_TEDUBOARD] (state, teduBoard) {
    state.teduBoard = teduBoard
  },
  [Types.SET_CAMERA_STATUS] (state, status) {
    state.cameraStatus = status
  },
  [Types.SET_USER_MUTE] (state, status) {
    state.isMute = status
  },
  [Types.SET_CURRENT_USER] (state, account) {
    state.currentUser = account
  },
  [Types.SET_IS_CONNECT] (state, status) {
    state.isConnect = status
  },
  [Types.SET_CONNECT_LIST] (state, list) {
    state.connectList = list
  },
  [Types.SET_HAND_UP_LIST] (state, list) {
    state.handUpList = list
  },
  [Types.SET_GROUP_MUTE] (state, status) {
    state.groupMute = status
  },
  [Types.SET_CURRENT_TEACHER] (state, teacher) {
    state.currentTeacher = teacher
  },
  [Types.SET_UPLOAD_QUALITY] (state, quality) {
    state.uploadQuality = quality
  },
  [Types.SET_DOWNLOAD_QUALITY] (state, quality) {
    state.downloadQuality = quality
  },
  [Types.SET_IS_ON_LONE] (state, quality) {
    state.isOnLone = quality
  },
  [Types.CLEAR_TIC] (state) {
    state.msgs = []
    state.userList = []
    state.tic = null
    state.groupInfo = {}
    state.handUpList = []
    state.connectList = []
    state.teduBoard = null
    state.isConnect = false
  },
  [Types.CLEAR_LISTS] (state) {
    state.userList = []
    state.handUpList = []
    state.connectList = []
  },

  [Types.SET_LIVE_MODE] (state, liveMode) {
    state.liveMode = liveMode
  },

  [Types.SET_IS_SHOW_FACE] (state, status) {
    state.isVideoCall = status
  },
  [Types.ADD_HISTORY_MSG] (state, list) {
    let msgArr = []
    list.forEach((msg,index)=>{
      let nextMsg = list[index+1]
      if(nextMsg&&(nextMsg.time-msg.time)>300){
        msgArr.push({
          content: formatMoment(msg.time,'HH:mm'),
          type: 'notify',
          avatar: '',
          nick: ''
        })
      }
      msgArr.push(msg)
    })
    state.msgs = msgArr.concat(state.msgs)
  }

}

function findUser (user, list) {
  let userIndex = ''
  list.forEach((ele, index) => {
    if (ele.userID === user.userID) {
      userIndex = index
    }
  })
  return userIndex
}
