import * as Types from './mutationTypes'

export default {
  setIsShowPaintBox: ({commit}, status) => {
    commit(Types.SET_IS_SHOW_PAINT_BOX, status)
  },
  setFileList: ({commit}, fileList) => {
    commit(Types.SET_FILE_INFO_LIST, fileList)
  },
  setCurrentFile: ({commit}, file) => {
    commit(Types.SET_CURRENT_FILE, file)
  },
  setFileBoxStatus: ({commit}, status) => {
    commit(Types.SET_FILEBOX_STATUS, status)
  }
}
