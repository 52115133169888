<template>
  <div class="page-Index pageContent" id="indexContent">
    <div class="section section-navsAndBanner">
      <div class="navs">
        <menu-list @setApiRequestTime="setApiRequestTime"></menu-list>
      </div>
      <div class="banners">
        <div class="swiper">
          <banner-swiper :swiper-data="adsData[adsPosition.MAIN_BANNER]" />
        </div>
        <div class="ad-box">
          <div class="ad ad1" v-for="(ad,index) in adsDataBanner" @click="turnPageTo(ad)" :key="index">
            <img v-lazy="ad.image_url" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="section section-activity">
      <activity-schedule />
    </div>
<!--    第二屏 懒加载-->
    <series-and-sup class="section lazy-load section-spu" id="sectionSpu" ref="sectionSpu"></series-and-sup>
    <material-and-article class="section lazy-load section-material-article" id="sectionMaterialArticle" ref="sectionMaterialArticle"></material-and-article>
    <div class="section  section-ad" id="sectionAD" ref="sectionAD">
      <div class="section-title">高效备考工具</div>
      <ad-list :ad-data="adsData[adsPosition.MATERIAL_SPEAKING]"></ad-list>
    </div>
    <div class="section  section-ad">
      <div class="section-title">仿真模考中心</div>
      <div class="section-container">
        <ad-list :ad-data="adsData[adsPosition.MOCK_EXAM]"></ad-list>
      </div>
    </div>

    <div class="section section-teachers lazy-load" id="sectionTeacher">
      <div class="section-title">权威师资 优质教师</div>
      <div class="section-container">
        <teacher class="section-teacher-container" ref="sectionTeacher"  />
      </div>
    </div>
    <div class="section section-score">
      <div class="section-top">
        <div class="section-title">学员优秀备考出分案例</div>
        <div class="section-category-list">
          <template  v-for="category in categoryList">
            <div class="category-item"
                 v-if="category.is_article_recommend"
                 :class="activeScoreSwipeCategory === category.abbr?'category-item-active':''"
                 :key="category.id"
                 @click="handleToggleScoreSwiper(category)"
            >
              {{ category.name }}
            </div>
          </template>

        </div>
      </div>
      <div class="section-container lazy-load" id="sectionArticle" >
        <score-swiper :swiper-data="articleSwiperData[activeScoreSwipeCategory]"></score-swiper>
        <div class="service-button-box">
          <div class="service-button" @click="callService">定制专属规划 <span>立即点击咨询</span></div>
        </div>
        <div class="qr-code" v-show="isShowQrcode">
          <div class="close-btn iconfont icon-papa-close-small" @click="delQrcodeBox"></div>
          <div class="title">立即咨询</div>
          <img class="qr-image" :src="qrImage" alt="">
          <div class="tip">微信扫码添加小助手，咨询课程</div>
        </div>
      </div>

    </div>
    <div class="section section-media">
      <div class="section-title">媒体报道</div>
      <div class="section-container">
        <div class="media-list">
          <div class="list-item" v-for="(item, index) in mediaData" :key="index" :style="!item.img?{border: 'none'}:{}">
            <div v-if="item.img" class="img-box" @click="openPage(item.href)">
              <img  v-lazy="item.img" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <float-bar category="ielts"></float-bar>
    <float-ads v-if="dataRefreshAt > 0" :otherAdsPosition="otherAdsPosition" @adsRes="getAdsCallback"></float-ads>
    <AdDialog :adDialogConfig='adDialogConfig' />
  </div>
</template>

<script>
  import IndexJs from './Index.js'

  export default IndexJs
</script>

<style lang="less">
.qr-code {
      width: 400px;
      height: 360px;
      background: #fff;
      box-shadow: 0px 13px 46px 0px rgba(86, 86, 86, 0.05);
      border-radius: 5px;
      position: fixed;
      top: 350px;
      left: 50%;
      margin-top: -180px;
      margin-left: -200px;
      z-index: 10000;

      & > .close-btn {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 20px;
        cursor: pointer;
      }

      & > .title {
        margin-top: 36px;
        font-size: 24px;
        color: #333;
        font-weight: bold;
      }

      & > .qr-image {
        width: 180px;
        height: 180px;
        border-radius: 5px;
        margin-bottom: 26px;
      }

      & > .tip {
        font-size: 18px;
        color: #333;
      }

      & > .reply {
        font-size: 16px;
        color: #666;
        margin-top: 8px;
      }
    }
  .page-Index {
    padding: 40px 0 90px 0;

    &>.section {
      margin-bottom: 50px;
      .section-top {
        align-items: center;
        display: flex;

        .section-category-list {
          overflow: auto;
          box-sizing: border-box;
          display: flex;
          margin-bottom: 30px;

          .category-item {
            padding: 10px 26px;
            border-radius: 21px;
            background: #fff;
            color: #666666;
            flex-shrink: 0;
            height: 20px;
            margin-right: 20px;
            text-align: center;
            line-height: 23px;
            cursor: pointer;
          }

          .category-item-active {
            background: #29d087;
            color: #fff;
          }
        }
      }
      .section-title {
        box-sizing: border-box;
        padding-left: 20px;
        text-align: left;
        flex-shrink: 0;
        font-size: 24px;
        font-weight: bold;
        color: #000;
        margin-bottom: 30px;
        margin-right: 30px;
      }

    }

    .section-navsAndBanner {
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;

      .navs {
        width: 250px;
        height: 544px;
        background: #ffffff;
        border-radius: 20px;
        margin-right: 30px;
      }

      .banners {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .swiper {
          background: #ffffff;
          width: 920px;
          height: 308px;
          border-radius: 20px;
        }

        .ad-box {
          display: flex;
          justify-content: space-between;

          .ad {
            background: #ffffff;
            width: 450px;
            height: 205px;
            border-radius: 20px;
            cursor: pointer;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 20px;
          }
        }
      }
    }
    .section-teachers {
      margin-bottom: 60px;

      .section-title {
        box-sizing: border-box;
        padding-left: 20px;
        width: 100%;
        text-align: left;
        margin-top: 60px;
        margin-bottom: 30px;
        font-size: 24px;
        font-weight: bold;
        color: #000;
      }

      .section-teacher-container {
        box-sizing: border-box;
        padding: 30px;
        width: 100%;
        height: 410px;
        background: #ffffff;
        border-radius: 28px;
      }
    }
    .section-score {
      box-sizing: border-box;

      .section-title {
        box-sizing: border-box;
        padding-left: 20px;
        width: 12em;
        text-align: left;
        flex-shrink: 0;
        font-size: 24px;
        font-weight: bold;
        color: #000;
      }

      .section-container {
        box-sizing: border-box;
        padding: 20px;
        width: 100%;
        height: 418px;
        border-radius: 28px;
        background: #fff;

        .service-button-box {
          width: 100%;
          display: flex;
          justify-content: center;

          .service-button {
            width: 300px;
            height: 60px;
            border-radius: 30px;
            background: #29d087;
            color: #fff;
            font-size: 16px;
            line-height: 60px;
            cursor: pointer;
            span {
              margin-left: 5px;
              color: #FFF441;
              font-size: 20px;
            }
          }
        }
      }
    }

    .section-media {
      width: 100%;

      .section-title {
        box-sizing: border-box;
        padding-left: 20px;
        width: 100%;
        text-align: left;
        margin-top: 60px;
        margin-bottom: 30px;
        font-size: 24px;
        font-weight: bold;
        color: #000;
      }

      .section-container {
        box-sizing: border-box;
        padding: 30px;
        height: 228px;
        border-radius: 28px;
        background: #fff;

        & > .media-list {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin: 0 auto;
          flex-wrap: wrap;

          & > .list-item {
            box-sizing: border-box;
            width: 270px;
            height: 74px;
            background: #fff;
            margin-bottom: 19px;
            border: 1px solid #F0F0F0;
            border-radius: 8px;
            overflow: hidden;

            & > .img-box {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              & > img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }

      }
    }
  }
</style>
