let isShowPaintBox = true
let fileInfoList = []
let currentFile = ''
let isShowFileListBox = false

export default {
  isShowPaintBox: isShowPaintBox,
  fileInfoList: fileInfoList,
  currentFile: currentFile,
  isShowFileListBox: isShowFileListBox
}
