/**
 * @typedef {number} activityType
 * @enum {activityType}
 *
 * */
const activityType = {
  trainingCamp: 'training_camp', // 训练营
  publicClass: 'public_class' //直播课
}

/**
 * @typedef {number} adsPosition
 * */
const adsPosition = {
  MAIN_BANNER: 2, //首页
  SIDE_BANNER: 3, //首页banner下方
  HOMEPAGE_BOTTOM: 4, //首页底部
  MATERIAL_SPEAKING: 6, //备考工具
  MOCK_EXAM: 7, // 模考中心
  DOWNLOAD_APP: 99, // 下载app
  FLOAT_LEFT: 103, // 左侧悬浮
  OfficialWebsitePopUp: 104,//中间弹窗
  OnlineExerAds: 26,//在线练习广告位
  MAIN_CLASSIFY_BANNER: 105, //官网Web 分类 Banner
  FLOAT_LEFT_BOTTOM:106
}

const adsTypePostion = {
  OfficialWebsitePopUp: 104,//官网中间弹窗
  IELTSPopUp: 105,//雅思中间弹窗
  TOEFLPopUp: 106,//TOEFL中间弹窗
  GREPopUp: 107,//GRE中间弹窗
  GMATPopUp: 108,//GMAT中间弹窗
  PTEPopUp: 109,//PTE中间弹窗
  PracticalEnglishPopUp: 110,//实用英语中间弹窗
}
const COURSE_MODEL = {
  'LIVE': 1, // 直播
  'RECORD': 2, // 录播
  'VIDEO': 3, // 视频
  'TRIAL': 4 // 抢先听
}


/**
 *@typedef {number} COURSE_TYPE
 *
 */
const COURSE_TYPE = {
  'COMMUNITY': 1, //公益课
  'LIVE_FEE': 2, //收费课
}
const CLASS_TYPE = {
  'PRIVATE': 1,
  'CLASS': 2
}
const LIVE_MODE = {
  'PRIVATE': 1,
  'CLASS': 2
}
/**
 * @typedef {string} ANALYTIC_TYPE
 * */
const ANALYTIC_TYPE = {
  EXIT: 'exit',
  CLICK: 'click',
  COPY: 'copy'
}

const PAY_TYPE = {
  'ALIPAY': 'alipay',
  'WECHAT': 'wechat',
}

const ORDER_TYPE = {
  'COURSE': 'course',
  'VIP': 'vip'
}
const PAY_STATUS = {
  UNPAID: 100,
  PAID: 101,
  PARTPAID: 102,
  REFUNDING: 200,
  REFUND: 201,
  CASH: 202,
  CLOSED: 300,
  CANCEL: 301,
  INVALID: 400
}

const PAY_STATUS_OPTIONS = {
  UNPAID: { key: 100, value: '待支付' },
  PAID: { key: 101, value: '已付款' },
  PARTPAID: { key: 102, value: '部分付款' },
  REFUNDING: { key: 200, value: '退款中' },
  REFUND: { key: 201, value: '已退款' },
  CASH: { key: 202, value: '部分退款' },
  CLOSED: { key: 300, value: '已关闭' },
  CANCEL: { key: 301, value: '已取消' },
  INVALID: { key: 400, value: '已作废' }
}

const PAY_STATUS_MAP = {
  100: 'UNPAID',
  101: 'PAID',
  102: 'PARTPAID',
  200: 'REFUNDING',
  201: 'REFUND',
  202: 'CASH',
  300: 'CLOSED',
  301: 'CANCEL'
}

const STOCK_STATUS = {
  NORMAL: 1,

  STOCK_SHORT: 2, // 库存紧张

  SOLD_OUT: 3 // 售罄
}

const SPU_DESCRIPTION_TYPE = {
  LIGHTSPOT: 1, // 班级亮点

  SUITABLE_FOR_PEOPLE: 2, // 适合学员

  PURCHASE_GUIDE: 3, // 购课说明
}

const PAY_DETAIL_STATUS = {
  UNPAID: 100,
  PAID: 101,
  FULL_AMOUNT_REFUND: 201,
  PARTIAL_REFUND: 202,
  INVALID: 400
}

const PAY_DETAIL_STATUS_MAP = {
  100: '待付款',
  101: '已付款',
  201: '全额退款',
  202: '部分退款',
  400: '已作废'
}

export {
  activityType,
  adsPosition,
  COURSE_MODEL,
  COURSE_TYPE,
  CLASS_TYPE,
  LIVE_MODE,
  ANALYTIC_TYPE,
  PAY_TYPE,
  ORDER_TYPE,
  PAY_STATUS,
  PAY_STATUS_OPTIONS,
  STOCK_STATUS,
  SPU_DESCRIPTION_TYPE,
  PAY_STATUS_MAP,
  PAY_DETAIL_STATUS,
  PAY_DETAIL_STATUS_MAP,
  adsTypePostion
}
