<template>
  <div
    id="app"
    :class="[bgColor ? 'bg-gray' : '', isPadding ? 'pageTopPadding' : '']"
    @click="isSearch"
  >
    <Header @handleMe="handleHeaderMe" ref="isSearchRef"></Header>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
    <Footer v-show="!$route.meta.noFooter"></Footer>
    <router-view name="secondPage" />
    <el-dialog
      title=""
      :visible.sync="informationDialog"
      :close-on-click-modal="false"
      :show-close="false"
      width="360px"
      lock-scroll
      center
    >
      <ImproveInformation
        @closeInformationDialog="closeInformationDialog"
      ></ImproveInformation>
    </el-dialog>
    <el-dialog
      title=""
      :visible.sync="firstSetPasswordDialog"
      :close-on-click-modal="false"
      width="360px"
      lock-scroll
      center
    >
      <SetPassword></SetPassword>
    </el-dialog>
    <div class="mask" v-if="globalMask"></div>

    <div class="video_box" v-show="videoDialog">
      <div class="close-btn" @click="closeVideo">x</div>
      <div class="video-con" style="width: 800px; height: 500px">
        <t-c-player
          class="player-size"
          ref="tcPlayer"
          @onPlayerPlay="onPlayerPlay"
          @onPlayerPause="onPlayerPause"
          :fid="currentVideoFid"
        ></t-c-player>
      </div>
    </div>

    <div class="global-error" v-if="globalErrorMsg">
      {{ globalErrorMsg.error }}
    </div>
  </div>
</template>

<script>
import Header from "./components/Header/Header.vue";
import {
  categoryType,
  trialPlayDuration,
  configInit,
} from "@/api/common/common";
import * as Api from "@/api/login/login";
import Footer from "@/components/Footer/Footer.vue";
import TCPlayer from "@/components/TCPlayer/TCPlayer.vue";
import { changeDevice } from '@/utils/utils'
import ImproveInformation from "@/components/improveImformation/improveInformation.vue";
import { mapState, mapActions } from "vuex";
import SetPassword from "@/components/Login/SetPassword.vue";
import { limitTime } from "@/api/newTrainingSpeaking/newTrainingSpeaking.js";
import Aegis from 'aegis-web-sdk';



export default {
  components: {
    Header,
    Footer,
    ImproveInformation,
    TCPlayer,
    SetPassword,
  },
  data() {
    return {
      loginType: "register",
      loginName: "",
      informationDialog: false,
      videoBeginTime: "",
      videoDruation: 0,
      intervalObj: null,
      isPadding: true,
    };
  },
  mounted() {
    const user  = localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))
    const aegis = new Aegis({
      id: '9GJeKFL6JG4LJDDR8l', // 上报 id
      env:  process.env.NODE_ENV === "production" ?  Aegis.environment.production : Aegis.environment.development,
      uin: user?.uuid, // 用户唯一 ID（可选）
      reportApiSpeed: true, // 接口测速
      reportAssetSpeed: true, // 静态资源测速
      spa: true, // spa 应用页面跳转的时候开启 pv 计算
      hostUrl: 'https://rumt-zh.com'
    });
    if (this.$cookies.isKey("authorization")) {
      let authorization = this.$cookies.get("authorization");
      sessionStorage.authorization = JSON.stringify(authorization);
      this.$store.dispatch("setAuthorization", {
        authorization: authorization,
      });

      Api.profileApi((res) => {
        localStorage.user = JSON.stringify(res);
        this.$store.dispatch("setUserInfo", { userInfo: res });
      }, "");
    }
    categoryType((res) => {
      this.$store.dispatch("setCategoryTypes", { types: res });
    });
    this.$router.afterEach((to, from, next) => {
      window.scrollTo(0, 0);
    });
    this.getConfigInit();
    setTimeout(() => {
      if (this.$route.meta && this.$route.meta.suitable) {
      } else {
        changeDevice(window.location.pathname);
      }
    }, 2000);
  },

  computed: {
    loginDialog: {
      get: function () {
        return this.$store.state.common.loginDialog;
      },
      set: function () {
        this.$store.dispatch("setLoginDialog", { status: false });
      },
    },
    bgColor() {
      let currentPath = this.$route.path;
      let currentRouteName = this.$route.name;

      const grayBgPageArr = ["Index", "Course", "Search", "ieltsIndex"];
      if (grayBgPageArr.includes(currentRouteName)) {
        return true;
      }
      if (currentPath.indexOf("liveDetail") > -1) {
        return true;
      } else if (currentPath.indexOf("user/schedule") > -1) {
        return true;
      } else if (currentPath.indexOf("user/course") > -1) {
        return true;
      } else if (currentPath.indexOf("user/activity") > -1) {
        return true;
      } else if (currentPath.indexOf("user/coupon") > -1) {
        return true;
      } else if (currentPath.indexOf("user/orders") > -1) {
        return true;
      } else if (currentPath.indexOf("user/account") > -1) {
        return true;
      } else if (currentPath.indexOf("user/trainingSpeaking") > -1) {
        return true;
      } else if (currentPath.indexOf("trainingSpeaking") > -1) {
        return true;
      }
      {
        return false;
      }
    },
    globalMask() {
      return this.$store.state.common.globalMask;
    },
    ...mapState({
      currentVideoFid: (state) => state.user.currentVideo.video_id,
      currentVideo: (state) => state.user.currentVideo,
      globalErrorMsg: (state) => state.common.globalError,
      videoDialog: (state) => state.user.videoDialog,
      firstSetPasswordDialog: (state) => state.common.firstSetPasswordDialog,
    }),
  },
  methods: {
    getConfigInit() {
      configInit("", (res) => {
        localStorage.setItem("configInit", JSON.stringify(res));
      });
    },
    handleHeaderMe(data) {
      this.isShowLogin = true;
    },
    closeDialog() {
      this.$store.dispatch("setLoginDialog", { status: false });
    },
    closeMask() {
      this.$store.dispatch("setGlobalMask", { status: false });
    },
    showInformationDialog() {
      this.informationDialog = true;
    },
    closeInformationDialog() {
      this.informationDialog = false;
    },
    closeVideo() {
      clearInterval(this.intervalObj);
      let videoObj = this.currentVideo;
      this.setVideoDialog(false);
      this.setCurrentVideo("");
      let params = {
        course_id: this.$route.params.id,
        course_schedule_id: videoObj.id,
        duration: this.videoDruation,
      };
      trialPlayDuration(params, () => {
        console.log("时长统计成功");
      });
    },
    onPlayerPlay() {
      this.intervalObj = setInterval(() => {
        this.videoDruation += 1;
        console.log(this.videoDruation);
      }, 1000);
    },
    onPlayerPause() {
      clearInterval(this.intervalObj);
    },
    ...mapActions(["setVideoDialog", "setCurrentVideo"]),
    // 点击任意处关闭搜索框
    isSearch() {
      this.$refs.isSearchRef.is_Search = false;
    },
  },
  watch: {
    videoDialog() {
      this.$refs.tcPlayer.initPlay(this.currentVideoFid);
    },
    "$route.path"(newVal, oldVal) {
      if (newVal.indexOf("live/") > -1) {
        this.isPadding = false;
      } else {
        this.isPadding = true;
      }
    },
  },
};
</script>
<style lang="less">
html,
body {
  width: 100%;
  background: #fff;
  margin: 0;
  padding: 0;
}

img {
  border: none;
}
img[lazy=loading] {
  width: 60px !important;
  height: 60px !important;
  max-width: 100%;
  max-height: 100%;
}

.video_box {
  /*width: 480px;*/
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99999999;

  .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #fff;
    font-size: 40px;
    cursor: pointer;
  }

  .video-con {
    width: 800px;
    height: 500px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -250px;
    margin-left: -400px;
  }

  /deep/ .video-player {
    width: 800px;
    height: 500px;
  }

  video {
    width: 800px;
    height: 500px;
  }
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-width: 1200px;
  height: 100%;
  padding-top: 80px;
  min-height: calc(100vh - 80px);
}

.bg-gray {
  background: #fafafa;
}

.pageTopPadding {
  background-color: #fafafa;
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
}

.global-error {
  position: absolute;
  top: 30%;
  left: 50%;
  margin-left: -210px;
  z-index: 999999999;
  width: 420px;
  height: 40px;
  padding-bottom: 10px;
  vertical-align: middle;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ebeef5;
  font-size: 18px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
  line-height: 40px;
  overflow: hidden;
  backface-visibility: hidden;
}

a {
  color: #000;
  font-weight: 400;
  text-decoration: none;

  &.router-link-exact-active {
    color: #29d087;
  }
}

.iconfont:before {
  margin-right: 10px;
}

textarea {
  resize: none;
}
</style>
