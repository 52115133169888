<template>
  <div class="module module-activitySchedule" v-if="activityList.length > 0">
    <div class="calendar">
      <div class="calendar-title">免费备考活动日历</div>
      <calendar :calendar-data="activityList"
                :can-to-next="canToNext"
                :can-to-prev="canToPrev"
                @changeView="changeView"
                @changeActivity="changeActivity"
      ></calendar>
    </div>
    <div class="activity"
         v-if="activityList"
         @mouseover.stop.prevent="handleMouseOver"
         @mouseleave="handleMouseLeave"
    >
      <div class="summary-list" ref="listViewRef">
        <div class="list-view" >
          <div
            class="view-item"
            v-for="(item,index) in activityList"
            :key="item.id"
            :class="currentActivity&&currentActivity.id === item&&item.id ? 'view-item-active' : ''"
            @click="handleChooseActivity(item, index)"
          >
            <div class="item-time" v-if="item&&item.started_at">
              {{ formatMoment(item&&item.started_at, "M月D日") }}
            </div>
            <div class="item-title"  v-if="item&&item.title">{{ item&&item.title }}</div>
<!--            <div class="item-range">{{index}}</div>-->
<!--            <div class="item-teacher">{{ item.teacher }}</div>-->
            <i
              v-show="currentActivity&&currentActivity.id === item.id"
              class="iconfont icon-papa-arrLeft"
            ></i>
          </div>
          <template v-if="activityList.length<3">
            <div class="view-item" v-for=" item in extraActivityList" :key="item"></div>
          </template>
        </div>
      </div>
      <div class="activity-img" @click="toOpenCourse">
        <img v-lazy="currentActivity&&currentActivity.homepage_image_url" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import ActivityScheduleJs from "./ActivitySchedule.js";
export default ActivityScheduleJs;
</script>

<style scoped lang="less" >
.module-activitySchedule {
  box-sizing: border-box;
  padding: 30px;
  width: 100%;
  background: #fff;
  display: flex;
  height: 466px;
  border-radius: 28px;
  justify-content: space-between;
  & > .calendar {
    width: 455px;
    .calendar-title {
      font-size: 24px;
      color: #000;
      font-weight: bold;
      text-align: left;
      margin-bottom: 17px;
    }
  }
  & > .activity {
    box-sizing: border-box;
    width: 655px;
    height: 405px;
    border-radius: 20px;
    display: flex;
    overflow: hidden;
    position: relative;
    .summary-list {
      width: 186px;
      height: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
      z-index: 2;
      .list-view {
        width: 180px;
        height: auto;
        color: #fff;
        .view-item {
          box-sizing: border-box;
          padding: 30px;
          width: 100%;
          height: 135px;
          cursor: pointer;
          background: #000000;
          opacity: 0.5;
          text-align: left;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          font-size: 14px;
          .item-time {
            font-size: 16px;
            font-weight: bold;
            position: relative;
          }
          .item-time::before {
            position: absolute;
            left: -10px;
            top: 9px;
            content: "";
            width: 4px;
            height: 4px;
            border-radius: 2px;
            background: #fff;
          }
        }
        .view-item-active {
          opacity: 1;
          position: relative;
          .icon-papa-arrLeft {
            transform: rotate(-90deg);
            position: absolute;
            right: -18px;
            top: 53px;
            color: #000;
            font-size: 30px;
          }
          .icon-papa-arrLeft:before {
            margin-right: 0px;
          }
          .item-time {
            color: #29d087;
          }
          .item-time::before {
            background: #29d087;
          }
        }
      }
    }
    .summary-list::-webkit-scrollbar {
      display: none;
    }
    .activity-img {
      position: absolute;
      top: 0;
      left: 0px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius:20px;
      overflow: hidden;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
