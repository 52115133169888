import * as types from './mutation-types.js'

export default {
    [types.SET_CURRENT_COURSE_DESC] (state, course) {
        if(course){
            sessionStorage.currentCourseDesc = JSON.stringify(course)
        }
        state.currentCourseDesc = course
    },
}
