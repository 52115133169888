import state from './state'
import actions from './actions'
import mutations from './mutations'
import getter from './getter'


export default {
  state,
  actions,
  mutations,
  getter
}
