// 引入插件
(function (w, d, n, a, j) {
  w[n] = w[n] || function () {
    return (w[n].a = w[n].a || []).push(arguments)
  }
  j = d.createElement('script')
  j.async = true
  j.src = 'https://qiyukf.com/script/f87666add5bf4c08d9c8a8d1d5b09004.js?hidden=1'
  d.body.appendChild(j)
})(window, document, 'ysf')



// 实例化
var isSdkReady = false
ysf('onready', function () {
  isSdkReady = true
})

clickHandler = function (active = false) {
  try {
    if (isSdkReady) {
      ysf('open')
      localStorage.setItem('todayDialg', JSON.stringify(new Date().getTime()))
    } else {
      // 仅做参考
      console.log('sdk尚未加载成功，请稍后再试')
    }
  }catch (e) {
    console.log(e)
  }

}
