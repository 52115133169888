import axios from 'axios'
import store from '@/store'
import { Message } from 'element-ui'
import Qs from 'qs'
import md5 from 'js-md5'

let headers = {
  channel: 'papaedu',
  platform: 'web',
  version: '1.0.0',
  'user-agent': navigator.userAgent,
  'app-name': 'papaedu',
}

function sortParams(target, md5Str) {
  let newMd5Str = md5Str
  const sortTarget = {}
  const sortKeys = Object.keys(target).sort()
  sortKeys.forEach((key, index) => {
    sortTarget[key] = encodeURIComponent(target[key])
    if (index === sortKeys.length - 1) {
      newMd5Str += `${encodeURIComponent(key)}=${encodeURIComponent(
        target[key]
      )}3c4c4a76abdfe223fe9bebc2fd1ddb71`
    } else {
      newMd5Str += `${encodeURIComponent(key)}=${encodeURIComponent(target[key])}&`
    }
  })
  return newMd5Str
}

axios.defaults.timeout = 300000
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
axios.defaults.withCredentials = true
axios.interceptors.request.use(
    ( config ) => {
      if (store.state.user.authorization.access_token) {
        config.headers.Authorization =
            store.state.user.authorization.token_type +
            ' ' +
            store.state.user.authorization.access_token
      }
      headers.timestamp = Math.ceil(new Date().getTime() / 1000)
      let md5Str = ''
      let target = {}
      if(config.params){
        target = {
          ...headers,
          ...config.params,
          path: config.url
        }
      }
      if (config.data) {
        target = {
          ...headers,
          ...config.data,
          path: config.url
        }
      }
      delete target.openid
      delete target.authorization
      delete target.sign
      delete target.channel
      md5Str = sortParams( target , md5Str)

      md5Str = md5Str.replaceAll('(', '%28').replaceAll(')', '%29').replaceAll('\'', '%27').replaceAll('!', '%21');
      headers.sign = md5(md5Str)
      // config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
      config.headers = {
        ...config.headers,
        channel: 'papaedu',
        platform: 'web',
        version: '1.0.0',
        'app-name': 'papaedu',
        sign: headers.sign,
        timestamp: headers.timestamp,
      }
      return config
    },
    ( error ) => {
      return Promise.reject(error)
    }
)

axios.interceptors.response.use(
    ( response ) => {
      return response
    },
    ( error ) => {
      console.log(error.response)
      switch (error.response.status) {
        case 400:
          Message.error(error.response.data.message)
          break
        case 401:
          localStorage.clear()
          sessionStorage.clear()
          Message.error('您的登录信息已过期，请重新登录')
            setTimeout(() => {
              Message.closeAll()
            }, 1000)
          store.dispatch('setLoginDialog', { status: true })
          break
        case 403:
          Message.error(error.response.data.message)
          break
        case 404:
          Message.error('请求异常，请刷新页面重试')
          break
        case 406:
          Message.error('权限不足，无法执行该操作')
          break
        case 422:
          Message.error(error.response.data.message)
          break
        case 423:
          Message.error(error.response.data.errors)
          break
        case 429:
          if (error.response.data.message === 'Too Many Attempts.') {
            Message.error('请求过于频繁，请稍后再试')
          } else {
            Message.error(error.response.data.message)
          }
          break
        case 500:

          Message.error('服务器异常，请联系客服人员')
          break
        case 503:
          store.dispatch('setGlobalMask', { status: true })
          console.log(error.response.data.message)
          store.dispatch('setGlobalError', {
            error: error.response.data.message,
          })
          break
        default:
          Message.error('服务器异常，请稍后重试 Code:' + error.response.status)
      }

      return Promise.reject(error.response.data)
    }
)

let fetch = function ( url, method, data, fn, errorFn, version = 'v1' ) {
  let options = {
    url: version + '/' + url,
    method: method,
  }
  options[ method === ('get' || 'delete') ? 'params' : 'data' ] = data
  axios(options)
      .then(( response ) => {
        switch (response.status) {
          case 200:
          case 201:
          case 204:
            if (response.data.data) {
              if (response.data.server_time) {
                fn &&
                fn(response.data, {
                  ...response.data.meta,
                  ...response.data.links,
                })
              } else {
                fn &&
                fn(response.data.data, {
                  ...response.data.meta,
                  ...response.data.links,
                })
              }
            } else {
              fn && fn()
            }
            break
          default:
            alert('网络异常，请重试')
        }
      })
      .catch(( error ) => {
        console.error(error)
        errorFn && errorFn()
      })
}

export default fetch
