import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import MetaInfo from 'vue-meta-info';
import './plugins/element.js';
import '../theme/index.css';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import '@/utils/qiyuService.js';
import VueCookies from 'vue-cookies';
import '@/assets/font/iconfont.css';
import VueLazyLoad from 'vue-lazyload';
import defaultPic from '@/assets/img/common/load.gif';
import errorPic from '@/assets/img/common/loadfailed.png';

import 'swiper/css/swiper.min.css'

import Constant from '@/utils/constant.js'

//fundebug
import * as fundebug from "fundebug-javascript";
import fundebugVue from "fundebug-vue";

// main.js
import microApp from '@micro-zoe/micro-app'
microApp.start({
  name: 'class-schedule',
})
microApp.router.setBaseAppRouter(router)


fundebug.init({
    silentConsole: true,
    apikey: '3d6dde0caf34d8fc06f28cc255e0ab910d7be2dab8cb635c5a564c34ab9b087e',
    silentWebsocket: true,
    silentDev: true,
    setHttpBody: true,
    appversion: '2.3.3', // todo 版本
});
fundebugVue(fundebug, Vue);
if(process.env.NODE_ENV === 'production'){
require('fundebug-revideo');
}
Vue.prototype.constant = Constant;
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(MetaInfo);
Vue.use(VueAwesomeSwiper);
Vue.use(VueCookies);
Vue.use(VueLazyLoad, {
    error: errorPic,
    loading: defaultPic,
})
Vue.use(VueAwesomeSwiper)

Vue.prototype.$bus = new Vue();

var _hmt = _hmt || []
window._hmt = _hmt;
(function() {
    if (window.location.host.match(/^www\.papaen\.com$/i)) {
        var hm = document.createElement('script');
        hm.src = 'https://hm.baidu.com/hm.js?fc6455cb0bec0660dcb84bca47704508';
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(hm, s);
    }
})();

router.beforeEach((to, from, next) => {
    if (to.meta.requireAuth) {
        if (Vue.$cookies.isKey('authorization')) {
            next();
        } else {
            next(
                (function() {
                    // store.dispatch('setLoginDialog', { status: true });
                    window.location.href = `${process.env.VUE_APP_LOGIN_URL}?redirect_url=${window.location.href}`
                    sessionStorage.pageto = JSON.stringify(to);
                    return false;
                })(store)
            );
        }
    } else {
        next();
    }
});

var vue = new Vue({
    router,
    store,
    render: (h) => h(App),
    mounted() {
        document.dispatchEvent(new Event('custom-render-trigger'));
    },
}).$mount('#app');

export default vue;
