let trtcClient = null
let localStream = null
let isPushing = false
let screenStream = null
let teacherStream = null
let studentStream = []

export default {
  trtcClient: trtcClient,
  localStream: localStream,
  isPushing: isPushing,
  screenStream: screenStream,
  volumeValue: .6,
  teacherStream: teacherStream,
  studentStream: studentStream
}
