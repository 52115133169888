<template>
  <el-dialog title="" 
    :visible.sync="dialogAdVisible" 
    :show-close="false" 
    custom-class="adDialogBox" 
    v-if="adDialogConfig.config.image_url"
    :close-on-click-modal="false"
  >
    <template v-slot:title>
      <div class="diaoHeader">
        <el-checkbox class="left" v-model="checked" @change="handleCheck">7天不再展示</el-checkbox>
        <i class="el-icon-circle-close right" @click="handleClose"></i>
      </div>
    </template>
    <img class="diaoDialog" :src="adDialogConfig.config.image_url" @click="handleClick">
  </el-dialog>
</template>

<script>
  import * as Api from '@/api/user/user'
  import moment from 'moment'
  import { resolveAddress } from '@/utils/utils'

  export default {
    name: 'AdDialog',
    data () {
      return {
        dialogAdVisible: false,
        checked:false
      }
    },
    props: {
      adDialogConfig:{
        type: Object,
        default:{
          type:'OfficialWebsite',//OfficialWebsite、IELTS、TOEFL、GRE、GMAT、PTE、PracticalEnglish
          config:{
            image_url:'',
            link_url:''
          }
        }
      },
      unAutoAcquire:{
        type: Boolean,
        default: false
      }
    },
    mounted () {
      if(!this.unAutoAcquire) this.init()
    },
    computed: {},
    
    methods: {
      init(){
        this.checked = false
        this.judgeAdShow()
      },
      //判断弹窗广告是否展示
      judgeAdShow(){
        this.$nextTick(()=>{
          let tsTimestamp = JSON.parse(localStorage.getItem('PAPAADDIALOGTIMESTAMP') || '{}')
          let tsTimestampObj = tsTimestamp[this.adDialogConfig.type] || {}
          if(tsTimestampObj.today && moment.unix(parseInt(tsTimestampObj.today, 10)).isSame(moment(), 'day')){
            this.dialogAdVisible = false
            return
          }
          let tsTimestampString = tsTimestampObj.timestamp
          if(tsTimestampString){
            let timestampDate = moment.unix(parseInt(tsTimestampString, 10));
            let diffInDays =  moment().diff(timestampDate, 'days');
            this.dialogAdVisible = diffInDays > 7 ? true : false
          }else {
            this.dialogAdVisible = true
          }
        })
      },
      handleCheck(val){
        let tsTimestamp = JSON.parse(localStorage.getItem('PAPAADDIALOGTIMESTAMP') || '{}')
        let tsTimestampObj = tsTimestamp[this.adDialogConfig.type] || {}
        if(val){
          tsTimestampObj.timestamp = moment().unix()
         
        }else {
          tsTimestampObj.timestamp = ''
        }
        tsTimestamp[this.adDialogConfig.type] = tsTimestampObj
        localStorage.setItem('PAPAADDIALOGTIMESTAMP', JSON.stringify(tsTimestamp))
      },
      handleClose(){
        let tsTimestamp = JSON.parse(localStorage.getItem('PAPAADDIALOGTIMESTAMP') || '{}')
        let tsTimestampObj = tsTimestamp[this.adDialogConfig.type] || {}
        tsTimestampObj.today = moment().unix()
        tsTimestamp[this.adDialogConfig.type] = tsTimestampObj
        localStorage.setItem('PAPAADDIALOGTIMESTAMP', JSON.stringify(tsTimestamp))
        this.dialogAdVisible = false
      },
      handleClick(){
        let link_url = resolveAddress(this.adDialogConfig.config)
        if (link_url !== '') {
          if (link_url.indexOf('http') > -1) {
              window.open(link_url)
          } else {
              window.location.href = link_url
          }
        }
      }
    },
  }
</script>

<style lang="less" scoped>
  /deep/ .adDialogBox{
    background: transparent;
    box-shadow: none;
    cursor: pointer;
    max-width:620px;
    .el-dialog__header{
      padding-bottom: 0;
    }
    .diaoHeader{
      width: 100%;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left{
        height: 100%;
        display: flex;
        align-items: center;
        .el-checkbox__label {
          color: #ffffff;
          text-align: center;
          font-size: 14px;
          font-family: "PingFang SC";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
        }
        .el-checkbox__inner{
          background-color: transparent;
          border-color: rgba(255, 255, 255, 0.3);
        }
      }
      .right{
        position: relative;
        font-size: 12px;
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        font-weight: 100;
        &::after{
          content: '';
          position: absolute;
          left: 50%;
          bottom: -5px;
          margin-left: -1px;
          width: 2px;
          height: 6px;
          background: rgba(255, 255, 255, 1);
        }
      }
    }
    .el-dialog__body {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .diaoDialog{
      width: 100%;
      border-radius: 12px;
      max-width:620px;
    }
  }
  
</style>
