<template>
  <div class="c-teacher" :style="{height: teacherData.length / 6 * 175 + 80 + 'px'}">
    <div class="team-list">
      <div
        class="list-item"
        v-for="(item, index) in teacherData"
        :key="index"
        @mouseover="teacherHoverTip(index)"
        @mouseleave="teacherLeave"
        :class="teacherHover && currentHoverIndex == index ? 'hover-style' : ''"
      >
        <div class="keep-message">
          <div class="avatar">
            <img v-lazy="item.avatar" alt=""  v-if="item.avatar"/>
            <img src="@/assets/img/common/avatar.png" alt="" v-else/>
          </div>
          <div class="name"><span>{{ item.name }}</span></div>
          <div class="name-cn">{{ item.name_cn}}</div>
        </div>
        <div
          class="show-desc"
          v-show="teacherHover && currentHoverIndex == index"
        >
          <div class="keep-message" style="visibility: hidden">
            <div class="avatar">
              <img v-lazy="item.avatar" alt="" />
              <!--                <img :src="item.avatar" alt="">-->
            </div>
            <div class="name"><span>{{ item.name }}</span><span>{{ item.cn_name }}</span></div>
            <div class="name-cn">{{ item.title}}</div>
          </div>
          <div class="desc">
            <div class="detail" v-html="item.introduction"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { teacherList } from '@/api/common/common'

export default {
  name: "Teacher",
  data() {
    return {
      teacherHover: false,
      currentHoverIndex: "",
      teacherData: [],
    };
  },
  props: {
    categoryId: {
      type:Number,
      default: 0
    }
  },
  mounted () {
  },
  methods: {
    init(){
      console.log('=>(Teacher.vue:64) ', );
      this.getTeacherList()
    },
    getTeacherList(){
      let params = {}
      if(this.categoryId){
        params.category_id= this.categoryId
      }
      teacherList(params, res=>{
        this.teacherData = res
      })
    },
    teacherHoverTip(index) {
      this.teacherHover = true;
      this.currentHoverIndex = index;
    },
    teacherLeave() {
      this.teacherHover = false;
      this.currentHoverIndex = "";
    },
  },
};
</script>

<style scoped lang="less">
.c-teacher {
  & > .team-list {
    width: 100%;
    margin: 0 auto;

    & > .hover-style {
      box-shadow: 0px 2px 14px 0px rgba(142, 142, 142, 0.07);
      /*height: 360px;*/
    }

    & > .list-item {
      position: relative;
      width: 190px;
      border-radius: 3px;
      display: flex;
      flex-direction: column;
      align-items: center;
      float: left;
      margin-bottom: 36px;

      & > .add-pic {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        text-align: center;
        line-height: 70px;
        font-size: 30px;
        font-weight: bold;
        background: #f5f8fa;
        margin: 20px 0;

        i {
          font-size: 30px;
          color: #cccccc;
        }

        i::before {
          margin-right: 0;
        }
      }

      & > .teacher-wait {
        font-size: 16px;
        color: #999;
        margin-top: 10px;
      }

      & > .keep-message {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        & > .avatar {
          width: 70px;
          height: 70px;
          border-radius: 50%;
          overflow: hidden;
          margin: 20px 0;

          & > img {
            width: 100%;
          }
        }

        & > .name,
        & > .name-cn {
          color: #333;
          font-size: 16px;
          font-weight: bold;
          background: #ffffff;
          height: 22px;
        }
      }

      & > .show-desc {
        box-shadow: 0px 2px 14px 0px rgba(142, 142, 142, 0.07);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9999;

        & > .keep-message {
          z-index: 99999;

          & > .avatar {
            width: 70px;
            height: 70px;
            border-radius: 50%;
            overflow: hidden;
            margin: 20px 0;

            & > img {
              width: 100%;
            }
          }

          & > .name,
          & > .name-cn {
            color: #333;
            font-size: 16px;
            font-weight: bold;
          }
        }

        & > .teach-course {
          font-weight: bold;
          text-align: left;
        }

        & > .desc {
          width: 190px;
          box-sizing: border-box;
          padding: 21px;
          min-height: 200px;
          text-align: justify;
          color: #555;
          background: #fff;
          font-size: 14px;
          white-space: break-spaces;

          & > .teach-course {
            font-weight: bold;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}
</style>
