import * as Types from './moutationTypes.js'

export default {
  setTrtcClient: ({commit}, trtcClient) => {
    commit(Types.SET_TRTC_CLIENT, trtcClient)
  },
  setLocalStream: ({commit}, localStream) => {
    commit(Types.SET_LOCAL_STREAM, localStream)
  },
  setIsPushIng: ({commit}, status) => {
    commit(Types.SET_IS_PUSHING, status)
  },
  setScreenStream: ({commit}, screenStream) => {
    commit(Types.SET_SCREEN_STREAM, screenStream)
  },
  setRemoteStream: ({commit}, teacherStream) => {
    commit(Types.SET_REMOTE_VIDEO_STREAM, teacherStream)
  },
  setVolumeValue: ({commit}, value) => {
    commit(Types.SET_VOLUME_VALUE, value)
  },
  addStudentStream: ({commit}, stream) => {
    commit(Types.ADD_STUDENT_STREAM, stream)
  },
  setStudentStream: ({commit}, streamArr) => {
    commit(Types.SET_STUDENT_STREAM, streamArr)
  },
}
