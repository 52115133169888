<template>
  <div class="ad-list">
    <template v-if="adData.length===1">
      <div class="ad-item ad-item-big">
        <img v-lazy="adData[0].image_url" @click="turnPageTo(adData[0])"   alt="">
      </div>
    </template>
    <template v-if="adData.length===2">
      <div class="item-wrap-middle">
        <div class="ad-item ad-item-middle">
          <img v-lazy="adData[0].image_url" @click="turnPageTo(adData[0])"  alt="">
        </div>
        <div class="ad-item ad-item-middle">
          <img v-lazy="adData[1].image_url" @click="turnPageTo(adData[1])"  alt="">
        </div>
      </div>
    </template>
    <template v-if="adData.length >= 3">
      <div class="ad-item ad-item-middle">
        <img v-lazy="adData[0].image_url" @click="turnPageTo(adData[0])"  alt="">
      </div>
      <div class="item-wrap">
        <div class="ad-item ad-item-small">
          <img v-lazy="adData[1].image_url" @click="turnPageTo(adData[1])"  alt="">
        </div>
        <div class="ad-item ad-item-small">
          <img v-lazy="adData[2].image_url" @click="turnPageTo(adData[2])"  alt="">
        </div>
      </div>
    </template>
  </div>
</template>
<script>
  export default {
    name: 'adList',
    props: {
      adData: {
        type: Array,
        default: () => {
          return []
        }
      }
    },
    methods: {
      turnPageTo ( item ) {
        _hmt.push(['_trackEvent', 'home_page_ads', 'click', item.title])
        if (item.address !== '') {
          if (item.address.indexOf('http') > -1) {
            window.open(item.address)
          } else {
            window.location.href = item.address
          }
        }
      },
    }
  }
</script>

<style lang="less" scoped>
  .ad-list {
    width: 100%;
    height: 420px;
    .item-wrap-middle{
      height: 100%;
      display: flex;
      justify-content: space-between;

    }
    .item-wrap{
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;

    }

    .ad-item {
      border-radius: 28px;
      overflow: hidden;
      background: #fff;
      float: left;
      font-size: 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      img{
        width: 100%;
        height: 100%;
      }

    }

    .ad-item-big {
      width: 100%;
      height: 420px;

    }

    .ad-item-middle {
      width: 590px;
      height: 420px;
      display: flex;
      align-content: center;
      justify-content: center;
    }

    .ad-item-small {
      width: 590px;
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>
