<template>
  <div class="score-swiper">
    <swiper
            class="swiper_article"
            :options="SwiperOption"
            @mouseenter.native.stop="mouseOverSlider"
            @mouseleave.native.stop="mouseLeaveSlider"
            ref="ieltsSwiper"
    >
      <swiper-slide
              v-for="(slide, index) in swiperData"
              :key="index"
      >
        <div>
          <a @click="turnToArticles(slide)">
            <div class="big">
              <h5>{{ slide.title }}</h5>
              <div class="slider_pic">
                <img v-lazy="slide.cover_image_url" alt="" />
              </div>
            </div>
            <p>
              {{ slide.description }}
            </p>
          </a>
        </div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>

  </div>
</template>

<script>
  export default {
    name: 'scoreSwiper',
    data () {
      return {
        // swiperData:[],
        SwiperOption: {
          slidesPerView: 4,
          autoplay: true,
          spaceBetween: 20,
          pagination: {
            clickable: true,
            el: '.swiper-pagination',
          },
        },
      }
    },
    props: {
      swiperData: {
        type: Array,
        default: () => {
          return []
        }
      }
    },
    methods: {
      mouseOverSlider () {
        this.swiperBtn = true
      },
      mouseLeaveSlider () {
        this.swiperBtn = false
      },
      turnToArticles ( item ) {
        _hmt.push(['_trackEvent', 'home_page_ads', 'click', item.title])
        this.$router.push({
          name: 'articleDetail',
          params: {
            id: item.id,
          },
        })
      },
    }
  }
</script>

<style lang="less" scoped>

  .score-swiper {
    height: 302px;
  }

  .swiper-container {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    box-sizing: border-box;
    padding: 0px 16px 20px;
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      .big {
        box-sizing: border-box;
        width: 270px;
        height: 200px;
        padding: 15px;
        border: 1px solid #f0f0f0;
        border-radius: 8px;

        h5 {
          line-height: 30px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-weight: bold;
          font-size: 16px;
          margin: 0px;
          text-align: left;
          margin-bottom: 5px;
        }

        .slider_pic {
          box-sizing: border-box;
          width: 100%;

          img {
            width: 100%;
            height: 110px;
          }
        }


      }

      P {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 24px;
        height: 50px;
        width: 270px;
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }

    //
    //  div {
    //    cursor: pointer;
    //
    //    .big {
    //      box-sizing: border-box;
    //      padding: 15px;
    //      width: 239px;
    //      height: 145px;
    //      border: 1px solid #29d087;
    //      border-radius: 4px;
    //

    //      .slider_pic {
    //        width: 100%;
    //        height: 120px;
    //        display: flex;
    //        align-items: center;
    //        justify-content: center;
    //        overflow: hidden;
    //      }
    //    }
    //

    //  }
    //
  }

  /deep/ .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    border-radius: 0;
    background-color: #29d087;
  }

  /deep/ .el-tabs__nav-wrap {
    display: flex;
    justify-content: center;
  }

  /deep/ .el-tabs__nav-wrap::after {
    display: none;
  }

  /deep/ .el-tabs__item {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #999999;
  }

  /deep/ .el-tabs__item.is-active {
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
  }

  /deep/ .el-tabs__active-bar {
    height: 4px;
    background: #29d087;
    border-radius: 2px;
  }

  .swiper_article {
    /deep/ .swiper-pagination-bullet {
      opacity: 1;
      background: #e6e6e6;
      border-radius: 4px;
      width: 6px !important;
    }

    /deep/ .swiper-pagination-bullet-active {
      opacity: 1;
      background: #29d087;
      border-radius: 4px;
      width: 10px !important;
    }
  }
</style>