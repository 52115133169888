import store from '@/store'
import * as Api from '@/api/common/common'
import NavBar from '@/components/NavBar/NavBar.vue'
import { reqHot } from '@/api/hot/hot'
import { switchCategory } from '@/utils/utils'
export default {
    mounted() {
        // this.setHotKeyWords();
        this.getSearchWords()
    },
    components: { NavBar },
    data() {
        return {
            avatar: '',
            dialogVisible: false,
            hotKeywords: [],
            homePage: '',
            dataNav: [{
                    name: '首页',
                    url: '/',
                },
                {
                    name: '雅思',
                    url: '/ielts',
                },
                {
                    name: '托福',
                    url: '/toefl',
                },
                {
                    name: 'GMAT',
                    url: '/gmat',
                },
                {
                    name: 'GRE',
                    url: '/gre',
                },
                {
                    name: 'PTE',
                    url: '/pte',
                },
                // {
                //   name: '学术英语',
                //   url: '/eap'
                // },
                {
                    name: '多邻国',
                    url: '/duolingo',
                },
                {
                    name: '实用英语',
                    url: '/practical',
                },
                {
                    name: '留学',
                    url: '/abroad',
                },
                {
                    name: '模考中心',
                    url: '/mockCenter',
                },
                {
                    name: 'APP下载',
                    url: '/download',
                },
            ],
            placeholder: '课程名称',
            searchValue: '',
            is_Search: false, // 控制搜索框显示隐藏
            hotArr: [] //热门搜索
        }
    },
    created() {
        this.categoryId(JSON.parse(localStorage.getItem('refreshCategoryId')) === null ? '' : JSON.parse(localStorage.getItem('refreshCategoryId')))
    },
    computed: {
        cartNumber() {
            sessionStorage.cartNumber = store.state.cart.cart.length
            return sessionStorage.cartNumber
        },
    },
    methods: {
        closeDialog(from) {
            this.dialogVisible = false
        },
        login() {
          window.open(`${process.env.VUE_APP_LOGIN_URL}?redirect_url=${window.location.href}`)
        },
        handleCommand(command) {
            if (command === 'account') {
                this.$router.push({
                    path: '/user/account',
                })
            }else  if (command === 'course') {
              this.$router.push({
                path: '/user/course',
              })
            }else  if (command === 'training') {
              this.$router.push({
                path: '/trainingSpeaking/main',
              })
            } else if (command === 'logout') {
                sessionStorage.clear()
                localStorage.clear()
                store.dispatch('setAuthorization', { authorization: {} })
                store.dispatch('setUserInfo', { userInfo: {} })
              const domain = process.env.VUE_APP_LOGIN_DOMAIN
              this.$cookies.remove('authorization', '/', domain)
                this.$router.push({
                    path: '/',
                })
            }
        },
        setHotKeyWords() {
            this.configInit = JSON.parse(localStorage.getItem('configInit')).text
            this.configInit.forEach((item) => {
                if (item.command === 'hot_search_keywords') {
                    this.hotKeywords = JSON.parse(item.content)
                }
            })
        },

        getSearchWords() {
            Api.searchWordList('', list => {
                this.hotKeywords = list.keywords
                this.setPlaceholder(list.keywords)
            })
        },

        handleSearch() {
            this.is_Search = false
            const keyWords = this.searchValue || this.placeholder
            this.$router.replace({
                path: '/search',
                query: {
                    keyWords: keyWords,
                },
            })
            this.searchValue = ''
        },
        handleChooseHotWord(keyWord) {
            this.placeholder = keyWord
            this.$router.replace({
                path: '/search',
                query: {
                    keyWords: keyWord,
                },
            })
        },

        setPlaceholder(keywords) {
            const keywordsLength = keywords.length
            const index = Math.floor((Math.random() * (keywordsLength)))
                // console.log(keywords[index])
                // this.placeholder = keywords[index]
        },

        openExam() {
            window.open('https://exam.papaen.com/')
        },
        logout() {},

        // 搜索按钮
        searchIconBtn() {
            this.is_Search = !this.is_Search
        },
        // 子组件传来的categoryId, 并且发送热门请求
        categoryId(value) {
            localStorage.setItem('refreshCategoryId', JSON.stringify(value))
            let data = {
                category_id: value
            }
            reqHot(data, (res) => {
                this.hotArr = res
            })
        },

        // 热门搜索点击
        hotSearch(val) {
            this.searchValue = val.content
            this.handleSearch()
        },
    },
    filters: {
        ellipsis: function(value) {
            if (!value) return ""
            if (value.length > 14) {
                return value.slice(0, 14) + "...";
            }
            return value
        }
    }
}
