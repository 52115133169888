<template>
  <div class="bigBox">
    <div class="header pageContent">
      <div class="left">
        <div class="logoBox">
          <router-link to="/">
            <img
                    class="logoPic"
                    src="../../assets/img/common/newLogo4.png"
                    alt=""

            />
          </router-link>
        </div>
      </div>
      <div class="section-search">
        <NavBar @categoryId="categoryId"></NavBar>
        <!-- <div class="search-box">
          <div class="search-input">
            <i class="el-icon-search"></i>
            <input type="text" v-model="searchValue" :placeholder="placeholder" />
          </div>
          <div class="search-button" @click="handleSearch">搜索</div>
        </div> -->
        <!-- <div class="search-words">
        <span>热门搜索:</span
        ><span
                class="hotWord"
                v-for="word in hotKeywords"
                :key="word"
                @click="handleChooseHotWord(word)"
        >{{ word }}
        </span>
        </div> -->
      </div>
      <div class="personNav" :style="{'margin-left':$store.state.user.userInfo.username ? 230+'px' : 142 + 'px'}">
        <!--      <router-link to="/cart">-->
                <div class="myCar el-icon-search" :class="is_Search ? 'searchColor' : ''" @click.stop="searchIconBtn">
                  <div class="cart-number" v-show="cartNumber>0">{{cartNumber}}</div>
                </div>
        <!--      </router-link>-->
        <!--      <div class="divide"></div>-->
        <router-link to="/user/schedule">
          <div class="myCourse" style="margin-right: 29px">我的课表</div>
        </router-link>
        <el-dropdown
                v-if="$store.state.user.userInfo.username"
                @command="handleCommand"
        >
          <div class="me iconfont">
            <router-link to="/user/" v-if="$store.state.user.userInfo.avatar">
              <img :src="[$store.state.user.userInfo.avatar]" alt="" />
            </router-link>
            <router-link to="/user/" v-else>
              <img src="../../assets/img/common/avatar.png" alt="" />
            </router-link>
          </div>
          <el-dropdown-menu style="width: 98px">
            <el-dropdown-item command="course"> 我的课程</el-dropdown-item>
            <el-dropdown-item command="training"> 外教陪练</el-dropdown-item>
            <el-dropdown-item command="account"> 账户设置</el-dropdown-item>
            <el-dropdown-item command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div v-else>
          <div style="margin-right: 0" @click="login" class="unlogin">
            登录/注册
          </div>
          <!--        <router-link class="me iconfont " to="/user/account"></router-link>-->
        </div>
      </div>
    </div>
    <div class="search" v-if="is_Search">
      <div class="center">
        <div class="hot" v-if="hotArr.length > 0">
          <div class="text">热门搜索</div>
          <div class="one">
            <div class="top" v-for="(item, index) in hotArr" :key="index" @click="hotSearch(item)">
              <div class="corner">{{index + 1}}</div>
              <div class="content">{{ item.content | ellipsis}}</div>
            </div>
          </div>
        </div>
        <div class="searchInput">
          <input type="text" v-model="searchValue" :placeholder="placeholder" @keyup.enter="handleSearch" @click.stop/>
          <i class="el-icon-arrow-right" @click="handleSearch"></i>
        </div>
      </div>
    </div>
    <!-- <div class="navBox" v-if="$route.name === 'Index'">
      <div class="navBoxCon pageContent">
        <div class="nav" v-for="item in dataNav" :key="item.url">
          <router-link v-if="item.name === '首页'" to="/" exact>
            <div>{{ item.name }}</div>
            <div class="under-line"></div>
          </router-link>
          <router-link v-else-if="item.type !== 'out'" :to="item.url">
            <div>{{ item.name }}</div>
            <div class="under-line"></div>
          </router-link>

          <div class="exam" @click="openExam" v-else :href="item.url">
            {{ item.name }}
          </div>
        </div>

      </div>
    </div> -->
  </div>
</template>

<script>
  import HeaderJs from './Header.js'

  export default HeaderJs
</script>

<style lang="less" scoped>
  .searchColor {
    color: #29D087 !important;
  }
  /*@import '../../assets/style/lib-base.less';*/
  .bigBox {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #f5f5f5;
    z-index: 999;
    .search {
      position: absolute;
      bottom: -80px;
      left: 0;
      width: 100%;
      height: 80px;
      background-color: #fff;
      border-top: 1px solid #f5f5f5;
      z-index: 999;
      .center {
        display: flex;
        justify-content: flex-end;
        width: 1200px;
        height: 80px;
        margin: 0 auto;

        .searchInput {
          position: relative;
          width: 320px;
          height: 46px;
          margin-top: 17px;
          input {
            width: 288px;
            height: 100%;
            border-radius: 40px 40px 40px 40px;
            outline:none;
            border: 1px solid #E7E7E7;
            padding: 0 16px;
          }
          i {
            position: absolute;
            top: 12px;
            right: 7px;
            font-size: 23px;
            color: #29D087;
            &:hover {
              cursor: pointer;
            }
          }
        }
        .hot {
          display: flex;
          width: 520px;
          height: 44px;
          margin-right: 40px;
          margin-top: 18px;
          .text {
            margin-right: 40px;
            font-size: 14px;
            color: #000;
          }
          .one {
            width: 423px;
            height: 50px;
            font-size: 12px;
            line-height: 20px;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            .top {
              width: 203px;
              height: 20px;
              display: flex;
              margin-bottom: 5px;
              &:nth-child(1) {
                margin-right: 15px;
                .corner {
                  background-color: #F05454;
                }
              }
              &:nth-child(2) {
                .corner {
                  background-color: #FF8329;
                }
              }
              .corner {
                width: 20px;
                height: 20px;
                font-size: 12px;
                line-height: 20px;
                margin-right: 8px;
                border-radius: 6px 1px 6px 1px;
                background-color: #D2D2D2;
                color: #fff;
                transform: scale(0.8);
              }
              &:hover {
                cursor: pointer;
              }
            }

          }
        }
      }
    }
  }
  .header {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;


    & > .left {

      display: flex;
      align-items: center;
      justify-content: space-between;

      & > .logoBox {
        min-width: 110px;

        & > a {
          width: 82px;
          height: 18px;
          margin-right: 60px;
          display: flex;


          & > .logoPic {

            width: 180px;
            height: 75px;
            margin-top: -26px;
            // image-rendering: pixelated
          }

          & > span {
            color: #333;
            font-size: 15px;
            font-weight: 500;
            line-height: 18px;
          }
        }
      }

    }

    & > .section-search {
      width: 520px;
      height: 100%;

      .search-box {
        box-sizing: border-box;
        width: 520px;
        height: 40px;
        display: flex;
        border-radius: 40px;
        border: 2px solid #29d087;
        overflow: hidden;
        background: #29d087;

        .search-input {
          box-sizing: border-box;
          padding: 2px 40px;
          width: 418px;
          height: 100%;
          overflow: hidden;
          background: #fff;
          position: relative;

          input {
            width: 100%;
            height: 100%;
            border: none;
            outline: none;
            padding: 0;
            color: #333;
          }

          .el-icon-search {
            position: absolute;
            left: 15px;
            font-size: 16px;
            top: 9px;
            color: #cccccc;
            font-weight: bold;
          }

          input::placeholder {
            color: #999999;
          }
        }

        .search-button {
          width: 98px;
          height: 36px;
          text-align: center;
          line-height: 36px;
          color: #fff;
          font-size: 16px;
          cursor: pointer;
          user-select: none;
        }
      }

      .search-words {
        margin-top: 10px;
        text-align: left;
        padding-left: 10px;

        & > span {
          font-size: 12px;
          color: #333;
        }

        .hotWord {
          cursor: pointer;
          margin-left: 5px;
        }
      }
    }

    & > .personNav {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      a {
        color: #333;
      }
      .myCar{
        margin-right: 16px;
        font-weight: bold;
        &:hover {
          cursor: pointer;
        }
      }

      & > .divide {
        width: 1px;
        height: 25px;
        background: #f2f2f2;
        margin: 0 12px;
      }

      & > a {
        & > .myCar {
          font-size: 20px;
          color: #d0d0d0;
          cursor: pointer;
          position: relative;

          & > .cart-number {
            position: absolute;
            top: -5px;
            right: 3px;
            background: red;
            color: #fff;
            width: 15px;
            height: 15px;
            border-radius: 15px;
            font-size: 12px;
            text-align: center;
            line-height: 15px;
          }
        }
      }

      .unlogin {
        width: 120px;
        height: 44px;
        background: #29d087;
        color: #fff !important;
        line-height: 44px;
        text-align: center;
        font-size: 14px;
        border-radius: 44px;
        display: block;
        cursor: pointer;
      }

      .me {
        font-size: 20px;
        color: #d0d0d0 !important;
        cursor: pointer;

        a {
          width: 30px;
          height: 30px;
          display: block;
        }

        img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }
      }
    }
  }
  .navBox{
    background: #fff;
    .navBoxCon {

      display: flex;
      justify-content: space-between;
      margin-right: 80px;
      height: 68px;
      line-height: 69px;
      margin: 0 auto;

      & > .nav {
        font-size: 14px;
        font-weight: 400;
        color: #333;
        position: relative;

        & > .exam {
          cursor: pointer;
        }
      }
    }
  }
</style>
