export const SET_TIC = 'set_tic'
export const SET_ROOM_ID = 'set_roomID'
export const ADD_MSG = 'add_msg'
export const SET_USER_LIST = 'set_user_list'
export const DEL_USER = 'del_user'
export const ADD_USER = 'add_user'
export const SET_GROUP_INFO = 'set_group_info'
export const SET_NOTIFICATION_STATUS = 'set_notification_status'
export const SET_USER_HAND_STATUS = 'set_user_hand_status'
export const SET_USER_CONNECT_STATUS = 'set_user_connect_status'
export const SET_CLASS_STATUS = 'set_class_status'
export const SET_MIC_STATUS = 'set_mic_status'
export const SET_VIDEO_STATUS = 'set_video_status'
export const SET_VOICE_STATUS = 'set_voice_status'
export const SET_SHARE_STATUS = 'set_share_status'
export const SET_VIDEO_WRAP = 'set_video_wrap'
export const SET_TEDUBOARD = 'set_teduBoard'
export const SET_CAMERA_STATUS = 'set_camera_status'
export const SET_USER_MUTE = 'set_user_mute'
export const SET_CURRENT_USER = 'set_current_user'
export const SET_IS_CONNECT = 'set_is_connect'
export const SET_CONNECT_LIST = 'set_connect_list'
export const SET_HAND_UP_LIST = 'set_hand_up_list'
export const SET_GROUP_MUTE = 'set_group_mute'
export const SET_USER_ROLE = 'set_user_role'
export const SET_CURRENT_TEACHER = 'set_current_teacher'
export const CLEAR_TIC = 'clear_tic'
export const CLEAR_LISTS = 'clear_list'

export const SET_LIVE_MODE = 'set_live_mode'
export const SET_IS_SHOW_FACE = 'set_is_show_face'
export const ADD_HISTORY_MSG = 'add_history_msg'

export const SET_UPLOAD_QUALITY = 'set_upload_quality'
export const SET_DOWNLOAD_QUALITY = 'set_download_quality'

export const SET_IS_ON_LONE = 'set_is_on_lone'


