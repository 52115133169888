import {
  ADD_CART_PRODUCT,
  CHANGE_COURSE_QTY,
  DEL_CART_PRODUCT,
  MODIFY_CART_PRODUCT,
  UPDATE_CART_PORDUCT, UPDATE_SELECT_PRODUCT
} from './mutation-types'

export default {
  addProduct: ({commit}, {product}) => {
    commit(ADD_CART_PRODUCT, product)
  },
  delProduct: ({commit}, {status}) => {
    commit(DEL_CART_PRODUCT, status)
  },
  modifyProduct: ({commit}, {index, porduct}) => {
    commit(MODIFY_CART_PRODUCT, index, porduct)
  },
  changeCourseQty: ({commit}, {qty}) => {
    commit(CHANGE_COURSE_QTY, qty)
  },
  updateCartProduct: ({commit}, {products}) => {
    commit(UPDATE_CART_PORDUCT, products)
  },
  updateSelectProduct: ({commit}, {products}) => {
    sessionStorage.selectCourse = JSON.stringify(products)
    commit(UPDATE_SELECT_PRODUCT, products)
  }
}
