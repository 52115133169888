<script>
  export default {
    name: 'commonFooter',
    props: {
      width: {
        type: Number,
        default: 1200
      }
    },
    data () {
      return {
        imagePrefix: 'https://tcdn.papaen.com/assets/image/common_footer/',
        snsData: [
          {
            icon: 'tiktok.png',
            activeIcon: 'tiktokActive.png',
            hoverImage: [
              {
                title: '趴趴雅思',
                image: 'tiktokHover.jpg',
                url: 'https://v.douyin.com/iRcPMSog/'
              }
            ],
            label: '抖音',
          },
          {
            icon: 'redbook.png',
            activeIcon: 'redbookActive.png',
            hoverImage: [
              {
                title: '趴趴雅思',
                image: 'redbookHover.jpg',
                url: 'https://www.xiaohongshu.com/user/profile/5bd96d45933397000133b775?xhsshare=CopyLink&appuid=56377106e4b1cf2ffec29b4c&apptime=1701416887'

              }
            ],
            label: '小红书',
          },
          {
            icon: 'tmall.png',
            activeIcon: 'tmallActive.png',
            hoverImage: [
              {
                title: '趴趴教育旗舰店',
                image: 'tmallHover.jpg',
                url: 'https://papajiaoyu.tmall.com/'

              }
            ],
            label: '天猫',
          },
          {
            icon: 'weibo.png',
            activeIcon: 'weiboActive.png',
            hoverImage: [
              {
                title: '趴趴雅思',
                image: 'weiboIeltsHover.jpg',
                url: 'https://weibo.com/u/3946500922'
              },
              {
                title: '趴趴GMAT',
                image: 'weiboGmatHover.jpg',
                url: 'https://weibo.com/u/6033011582'
              }
            ],
            label: '微博'
          },
          {
            icon: 'wechat.png',
            activeIcon: 'wechatActive.png',
            hoverImage: [
              {
                title: '趴趴雅思',
                image: 'wechatIeltsHover.jpg'
              },
              {
                title: '趴趴托福',
                image: 'wechatToeflHover.jpg'
              },
              {
                title: '趴趴北美留学',
                image: 'wechatBMHover.jpg'
              },
              {
                title: '趴趴PTE',
                image: 'wechatPteHover.jpg'
              }
            ]
          }
        ],
        linkData: [
          {
            title: '我们的课程',
            links: [
              {
                title: '雅思',
                url: 'https://www.papaen.com/ielts'
              },
              {
                title: '托福',
                url: 'https://www.papaen.com/toefl'
              },
              {
                title: 'GMAT',
                url: 'https://www.papaen.com/gmat'
              },
              {
                title: 'GRE',
                url: 'https://www.papaen.com/gre'
              },
              {
                title: 'PTE',
                url: 'https://www.papaen.com/pte'
              }
            ],
          },
          {
            title: '我们的模考',
            links: [
              {
                title: '雅思',
                url: 'https://ielts.papaen.com'
              },
              {
                title: '托福',
                url: 'https://toefl.papaen.com'
              },
              {
                title: 'GMAT',
                url: 'https://gmat.papaen.com'
              }
              // {
              //   title: 'GRE',
              //   url: 'https://gre.papaen.com'
              // },

            ],
          },
          {
            title: '我们的APP',
            links: [
              {
                title: '趴趴英语',
                url: 'https://www.papaen.com/download'
              },
              {
                title: '趴趴雅思',
                url: 'https://www.papaen.com/download?id=1'
              },
              {
                title: '趴趴GMAT',
                url: 'https://www.papaen.com/download?id=2'
              }
            ],
          },
          {
            title: '联系我们',
            links: [
              {
                title: '电话',
                text: '400-167-1770'
              },
              {
                title: '在线客服',
                text: '在线客服'
              }
            ],
          },
          {
            title: '关于我们',
            links: [
              {
                title: '隐私协议',
                url: 'https://m.papaen.com/terms/public/newPrivacy/privacyPolicy'
              },
              {
                title: '关于趴趴',
                url: 'https://www.papaen.com/aboutUs'
              },
              {
                title: '加入我们',
                url: 'https://www.papaen.com/joinUs'
              }
            ],
          }
        ],
      }
    },
    methods: {
      handleClick ( link ) {
        if (link.url) {
          window.open(link.url)
        }else {
          if(link.text === '在线客服')
            clickHandler(true)
        }
      }
    }
  }
</script>

<template>
  <div class="footer">
    <div class="sns"  v-bind:style="{minWidth: width + 'px', backgroundImage: 'url('+imagePrefix+'root-channel.png)'}">
      <div class="sns-wrapper">
        <div class="sns-title">
          <img :src="imagePrefix+'title-image.png'" alt="" class="title-image">
        </div>
        <div class="sns-items">
          <div class="sns-item" v-for="item in snsData" :key="item.icon">
            <div class="sns-item-icon">
              <img :src="imagePrefix+item.icon" alt="" class="icon">
              <img :src="imagePrefix+item.activeIcon" alt="" class="icon-active">
            </div>
            <div class="hover-panel"  v-bind:style="{marginLeft: -(item.hoverImage.length * 90 -25)/2 + 'px' }">
              <div v-for="(hover, hoverIndex) in item.hoverImage" :key="'ho'+hoverIndex" style="display: flex">
                <div class="hover-item" @click="handleClick(hover)" >
                  <img :src="imagePrefix+hover.image" alt="">
                  <div class="item-label">{{ hover.title }}</div>
                </div>
                <div class="item-divider" v-if="hoverIndex<item.hoverImage.length-1" :key="hoverIndex">
                  <div class="line"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="con">
      <div class="con-wrapper" v-bind:style="{width: width + 'px'}">
        <div class="top">
          <div class="section" v-for="(item,index) in linkData" :key="index">
            <div class="title">{{ item.title }}</div>
            <div class="item" v-for="(link,linkIndex) in item.links" :key="linkIndex" @click="handleClick(link)">
              <div class="item-desc" >{{ link.text || link.title }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright-box" v-bind:style="{minWidth: width + 'px'}">
      <a href=" " target="_blank" style="position: relative;display: inline-block;height: 38px;">
        <div style="width:100%;height:100%;position: absolute;top: 0;left: 0;"></div><embed src="https://program.xinchacha.com/web/1663368928711086080=*.papaen.com.svg"width="103" height="38" type="image/svg+xml" pluginspage="//www.adobe.com/svg/viewer/install/"/></a >
      <a href="https://beian.miit.gov.cn" target="_blank" class="copyright-first-line">沪ICP备2023023608号-2</a>
      <a class="copyright" href="https://beian.miit.gov.cn" target="_blank" v-bind:style="{width: width + 'px'}">
        Copyright © 2015-2024 上海彼伴网络科技有限公司版权所有
      </a>
    </div>
  </div>
</template>

<style scoped>
  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
  /* General footer styles */
  .footer {
    width: 100%;
    font-size: 14px;
    color: #E6E6E6;
    position: relative;
    bottom: 0;
    z-index: 999;
  }

  /* SNS section styles */
  .footer > .sns {
    width: 100%;
    height: 240px;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
  }

  .footer > .sns .sns-wrapper {
    width: 605px;
    margin: 0 auto;
  }

  .footer > .sns .sns-title {
    width: 100%;
    margin-bottom: 20px;
  }

  .footer > .sns .sns-title img {
    width: 100%;
  }

  .footer > .sns .sns-items {
    width: 100%;
    display: flex;
    padding: 0 50px;
    justify-content: space-between;
  }

  .footer > .sns .sns-item {
    width: 50px;
    cursor: pointer;
    position: relative;
  }

  .footer > .sns .sns-item .hover-panel {
    margin-top: 10px;
    position: absolute;
    display: none;
    padding: 10px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 16px 0 #3d3d3d1a;
  }

  .footer > .sns .sns-item .hover-panel .hover-item {
    padding: 0 5px;
  }

  .footer > .sns .sns-item .hover-panel .item-divider {
    display: flex;
    height: 80px;
    align-items: center;
  }

  .footer > .sns .sns-item .hover-panel .item-divider .line {
    width: 1px;
    height: 40px;
    background: #777;
  }

  .footer > .sns .sns-item .hover-panel img {
    padding: 0 5px;
    width: 90px;
    height: 80px;
  }

  .footer > .sns .sns-item .hover-panel .item-label {
    font-size: 12px;
    color: #333;
    text-align: center;
  }

  .footer > .sns .sns-item .hover-panel .item-label:hover {
    color: #29d087;
  }

  .footer > .sns .sns-item .icon {
    display: block;
  }

  .footer > .sns .sns-item .icon-active {
    display: none;
  }

  .footer > .sns .sns-item:hover .hover-panel {
    display: flex;
  }

  .footer > .sns .sns-item:hover .icon {
    display: none;
  }

  .footer > .sns .sns-item:hover .icon-active {
    display: block;
  }

  .footer > .sns .hover-panel:after {
    content: "";
    position: absolute;
    top: -10px;
    left: 50%;
    margin-left: -10px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
  }

  .footer > .sns .sns-item-icon {
    width: 100%;
  }

  .footer > .sns .sns-item-icon img {
    width: 100%;
  }

  /* Content section styles */
  .footer > .con {
    background: #2D2E32;
    height: 374px;
    padding-top: 54px;

  }
  .footer > .con .con-wrapper {
    margin: 0 auto;
  }

  .footer > .con .con-wrapper > .top {
    display: flex;
    justify-content: space-between;
    text-align: left;
  }

  .footer > .con .con-wrapper > .top .section {

  }

  .footer > .con .con-wrapper > .top .section .title {
    font-size: 18px;
    color: #fff;
    margin-bottom: 20px;
  }

  .footer > .con .con-wrapper > .top .section .item {
    margin-bottom: 12px;
  }

  .footer > .con .con-wrapper > .top .section .item .item-desc {
    font-size: 14px;
    margin-bottom: 12px;
    color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
  }

  .footer > .con .con-wrapper > .top .section .item .item-desc:hover {
    color: #29d087;
  }

  /* Copyright section styles */
  .footer > .copyright-box {
    padding: 10px 0;
    width: 100%;
    height: 60px;
    position: absolute;
    font-size: 12px;
    color: #7a7a7d;
    text-align: center;
    bottom: 30px;
    border-top: 1px solid #3E3E40;
  }

  .footer> .copyright-box > .copyright-first-line {
    display: flex;
    align-items: center;
    justify-content: center;

    embed{
      width: 100px;
      height: 40px;
      margin-right: 10px;
    }
  }

  .footer > .copyright-box > .copyright {
    margin: 0 auto;
    margin: 0 auto;
    display: block;
  }

  .footer > .copyright-box:hover {
    cursor: pointer;
    color: #29d087;
  }

  .footer > .copyright-box:hover a {
    color: #29d087;
  }

  /* Global anchor styles */
  .footer > .copyright-box >  a {
    color: #7a7a7d;
    font-weight: 400;
    text-decoration: none;
  }

</style>
