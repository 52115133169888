import Vue from 'vue'
import Vuex from 'vuex'

import user from './user'
import common from './common'
import cart from './cart'
import live from './live/live'
import rtc from './live/rtc'
import board from './live/board'
import im from './live/im'
import calendar from './calendar'
import course from './course'
import footer from './live/footer'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        user,
        common,
        cart,
        live,
        rtc,
        im,
        board,
        calendar,
        course,
        footer
    }
})
