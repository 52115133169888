<template>
  <div class="tcplayer papaplayer">
    <video
      id="player-container-id"
      class="player-size"
      preload="auto"
      playsinline
      webkit-playsinline
    ></video>
  </div>
</template>

<script>
import TCPlayerJs from "@/components/TCPlayer/TCPlayer.js";

export default TCPlayerJs;
</script>

<style lang="less" scoped>
.tcplayer {
  width: 100%;
  height: 100%;

  .player-size {
    width: 100%;
    height: 100%;
  }

  /deep/ .draw-fill {
    display: none;
  }

  /deep/ .tcp-skin .vjs-big-play-button .vjs-button-icon {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }
}
</style>
