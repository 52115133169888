<template>
  <div class="menu">
    <div class="menu-list">
      <template
              v-for="(item, index) in navs"
      >
        <div class="menu-item" v-if="index<7" :key="item.id"
             @mouseover.stop.prevent.self="handleMouseOver(item)"
             @mouseleave.stop.prevent.self="handleMouseLeave(item)"
        >
         <div class="menu-item-show">
           <div class="triangle"></div>
           <div class="item-title">{{ item.name }}</div>
           <div class="item-sub-title-list">
            <span class="item-sub-title-item" v-for="sub in item.subtitle" :key="sub.id"
                  @click="handleOpenUrl(sub)"
            >{{ sub.name }}</span>
           </div>
         </div>
          <div class="sub-menu" v-if="isShowSubMenu &&item.id === activeMenuId && item.children && item.children.length>0">
            <div class="item-second-list">
              <div class="second-list-item" v-for="subMenu in subMenuData.children" :key="subMenu.id">
                <span class="second-title" >{{subMenu.name}}</span>
                <div class="second-box">
                  <span class="second-nav" v-for="nav in subMenu.children" :key="nav.id" @click="handleOpenUrl(nav)">{{nav.name}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import * as Api from '@/api/index/index'

  export default {
    name: 'menuList',
    data () {
      return {
        navs: [],
        isShowSubMenu: false,
        subMenuData: null,
        activeMenuId: '',
      }
    },
    mounted () {
      this.getMenuList()
    },
    methods: {
      getMenuList () {
        Api.menuListApi(res => {
          this.navs = res
          this.$emit('setApiRequestTime', performance.now())
        })
      },
      handleMouseOver(item){
        this.isShowSubMenu = true
        this.subMenuData = item
        this.activeMenuId = item.id
      },
      handleMouseLeave(){
        this.isShowSubMenu = false
        this.subMenuData = null
      },
      handleOpenUrl(target){
        window.open(target.link_url)
      }
    }
  }
</script>

<style lang="less" scoped>
  .menu {
    box-sizing: border-box;
    padding: 13px 0;
    position: relative;
    display: flex;
    & > .menu-list {
      width: 300px;
      flex: 1;
      .menu-item {
        box-sizing: border-box;
        padding: 6px 20px;
        width: 100%;
        min-height: 74px;
        border-radius: 20px;
        text-align: left;
        .menu-item-show{
          .item-title{
            margin-bottom: 5px;
            font-size: 20px;
            font-weight: bold;
            color: #333;
            cursor: pointer;
          }
          .item-sub-title-list{
            font-size:14px;
            color: #666;
            .item-sub-title-item{
              cursor: pointer;
              margin-right: 15px;
              color: rgba(0,0,0,0.7);
            }
          }
        }
        &> .sub-menu{
          box-sizing: border-box;
          padding: 30px;
          width: 500px;
          height: 544px;
          position: absolute;
          right: -500px;
          top: 0;
          z-index: 2;
          background: #fff;
          border-radius: 20px;
          box-shadow: 0px 0px 16px 0px rgba(131, 131, 131, 0.08);
          .item-second-list{
            user-select: none;
            .second-list-item{
              display: flex;
              font-size: 14px;
              margin-bottom: 20px;
              .second-box {
                width: 330px;
              }
              .second-title{
                color: #999999;
                margin-right: 15px;
                padding-top: 5px ;
              }
              .second-nav{
                color: #000000;
                margin-right: 10px;
                cursor: pointer;
                line-height: 30px;
              }
            }

          }
        }
      }
      .menu-item:hover{
        background: #29d087;
        .item-title{
          color: #fff;
        }
        .item-sub-title-list{
          .item-sub-title-item{
            color: #fff;
          }
        }
        .menu-item-show{
          position: relative;
          .triangle{
            z-index: 3;
            position: absolute;
            right: -26px;
            top: 12px;
            width: 18px;
            height: 18px;
            background: #29d087;
            border-radius: 4px;
            transform: rotate(45deg);
          }
        }

      }
    }

  }
</style>
