<template>
  <div class="series-spu">
    <div class="section-top">
      <div class="section-title">课程优选</div>
      <div class="section-category-list">
        <template  v-for="category in categoryList">
          <div class="category-item"
               v-if="category.is_spu_recommend"
               :class="activeCategory === category.abbr?'category-item-active':''"
               :key="category.id"
               @click="handleToggleCategory(category)"
          >
            {{ category.name }}
          </div>
        </template>

      </div>
    </div>
    <div class="section-container">
      <div class="series" :style="{backgroundImage: 'url('+seriesBg+')'}">
        <div class="category">{{ activeCategory }}</div>
        <div class="series-list"  v-if="seriesData[activeCategory]" >
          <a class="series-item"
             v-for="series in seriesData[activeCategory].series"
             :key="series.id"
             :href="`/${activeCategory.toLowerCase()}/series?seriesId=${series.id}`"
          >
            {{series.name}}
          </a>
        </div>
      </div>
      <div class="spus" v-if="seriesData[activeCategory]">
        <a class="spu-item"
           v-for="spu in seriesData[activeCategory].spus"
           :key="spu.name"
           :href="`/course/${spu.id}/${spu.default_sku_id}`"
        >
          <div class="item-top">
            <div class="spu-name">{{ spu.name }}</div>
            <div class="spu-tips">{{ spu.tips }}</div>
          </div>
          <div class="item-bottom">
            <div class="spu-teachers">
              <div class="teacher-item" v-for="(teacher, index) in spu.teachers" :key="teacher.name + spu.name + index">
                <div class="avatar-box">
                  <img class="teacher-avatar" v-lazy="teacher.avatar" alt="" v-if="teacher.avatar">
                  <img class="teacher-avatar" src="../img/defaultheader.png" alt="" v-else>
                </div>
                <div class="teacher-name">{{ teacher.name }}</div>
              </div>
            </div>
            <span class="spu-time" v-if="spu.type == spuType.liveClass">{{ formatMoment(spu.course.started_at, 'MM月DD日') }} - {{ formatMoment(spu.course.ended_at, 'MM月DD日') }}</span>
            <span class="spu-time" v-else>随时开课</span>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import * as Api from '@/api/index/index'
  import {formatMoment} from '@/utils/utils'
  import seriesBg from '../img/course_bg.png'

  export default {
    name: 'seriesAndSup',
    data () {
      return {
        activeCategory: 'IELTS',
        activeCategoryId: 1,
        seriesData: {},
        spuType: {
          livePersonal: 1, // 1v1
          liveClass: 2,    // 直播班课
          videoCourse: 3   // 视频班课
        },
        seriesBg: seriesBg
      }
    },
    computed: {
      ...mapState({
        categoryList: state => state.common.categoryTypes
      })
    },
    mounted () {
      //
    },
    methods: {
      init(){
        this.getData({ id: '1', abbr: 'IELTS' })
      },
      getData ( category ) {
        Api.spuRecommend(category.id, res => {
          this.$set(this.seriesData, category.abbr, res)

        })
      },
      handleToggleCategory ( category ) {
        if (this.seriesData[ category.abbr ]) {

        } else {
          this.getData(category)
        }
        this.activeCategory = category.abbr
        this.activeCategoryId = category.id
      },
      formatMoment
    }
  }
</script>

<style lang="less" scoped>
  .series-spu {
    .section-container {
      box-sizing: border-box;
      padding: 30px;
      width: 100%;
      height: 564px;
      border-radius: 20px;
      background: #fff;
      display: flex;
      justify-content: space-between;

      .series {
        // box-sizing: border-box;
        // padding: 30px 20px;
        width: 210px;
        height: 504px;
        border-radius: 20px;
        margin-right: 20px;
        background-position: center;
        background-size: cover;
        flex-shrink: 0;

        .category {
          width: 100%;
          height: 70px;
          line-height: 70px;
          text-align: center;
          font-size: 45px;
          font-family: DINOT;
          font-weight: bold;
          color: #FFFFFF;
          opacity: 0.2;
          margin-bottom: 16px;
          margin-top: 20px;
        }

        .series-list {
          .series-item {
            display: block;
            width: 170px;
            height: 82px;
            background: #FFFFFF;
            border-radius: 8px;
            margin-bottom: 14px;
            margin-left: 20px;
            color: #09A862;
            font-size: 18px;
            line-height: 82px;
            opacity: 0.6;
            cursor: pointer;
          }
        }
      }

      .spus {
        box-sizing: border-box;
        width: 940px;
        height: 504px;
        display: flex;
        flex-wrap: wrap;
        flex-shrink: 0;
        .spu-item {
          box-sizing: border-box;
          padding: 20px;
          width: 290px;
          height: 242px;
          border-radius: 8px;
          background: #fff;
          border: 1px solid #F0F0F0;
          margin-bottom: 20px;
          margin-right: 20px;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          text-align: left;
          cursor: pointer;
          &:nth-child(3) {
            margin-right: 0;
          }
          &:nth-child(6) {
            margin-right: 0;
          }
          .spu-name {
            width: 100%;
            font-size: 20px;
            font-weight: bold;
            color: #333;
          }

          .spu-tips {
            font-size: 14px;
            color: #666;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .spu-name, .spu-tips {
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .spu-teachers {
            display: flex;
            margin-bottom: 10px;

            .teacher-item {
              margin-right: 16px;
              flex-shrink: 0;

              .avatar-box {
                width: 40px;
                height: 40px !important;
                border-radius: 50%;
                display: flex;
                align-items: baseline;
                margin-bottom: 6px;
                overflow: hidden;

              }

              .teacher-avatar {
                width: 100%;
              }

              .teacher-name {
                font-size: 12px;
                color: #000;
                text-align: center;
              }
            }
          }

          .spu-time {
            padding: 4px;
            background: #EFF8F4;
            color: #666666;
            font-size: 14px;
            border-radius: 4px;
          }
        }
      }
    }
  }
</style>
