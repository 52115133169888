import Fetch from '@/utils/fetch.js'

// 账号密码登录
const loginApi = ( data, fn, errorFn, dataLevel ) => {
  new Fetch('login', 'post', data, fn, errorFn, dataLevel)
}

// 用户信息
const profileApi = ( fn, errorFn, dataLevel ) => {
  new Fetch('me', 'get', '', fn, errorFn, dataLevel)
}

// 获取极验配置
const geetest = ( fn, errorFn, dataLevel ) => {
  new Fetch('geetest/config/web', 'get', '', fn, errorFn, dataLevel)
}

// 获取登录验证码
const getLoginCode = ( data, fn, errorFn, dataLevel ) => {
  new Fetch('captcha/login/web', 'post', data, fn, errorFn, dataLevel)
}

// 验证码登录
const captchaLogin = ( data, fn, errorFn, dataLevel ) => {
  new Fetch('login/captcha', 'post', data, fn, errorFn, dataLevel)
}

// 获取注册验证码
const getRegisterCode = ( data, fn, errorFn, dataLevel ) => {
  new Fetch('captcha/register/web', 'post', data, fn, errorFn, dataLevel)
}

// 注册
const register = ( data, fn, errorFn, dataLevel ) => {
  new Fetch('register', 'post', data, fn, errorFn, dataLevel)
}

// 获取重置密码验证码
const getForgotCode = ( data, fn, errorFn, dataLevel ) => {
  new Fetch(
      'captcha/forgot_password/web',
      'post',
      data,
      fn,
      errorFn,
      dataLevel
  )
}

//忘记密码
const forgot = ( data, fn, errorFn, dataLevel ) => {
  new Fetch('forgot/password', 'post', data, fn, errorFn, dataLevel)
}

// 完善个人信息
const imporveInformation = ( data, fn, errorFn, dataLevel ) => {
  new Fetch('profile/basic', 'post', data, fn, errorFn, dataLevel)
}

// 个人信息下拉
const informationOptions = ( fn, errorFn, dataLevel ) => {
  new Fetch('complete_step/options', 'get', '', fn, errorFn, dataLevel)
}

// 是否需要个人信息
const profileCheck = ( type, data, fn, errorFn, dataLevel ) => {
  new Fetch('complete_step', type, data, fn, errorFn, dataLevel)
}

// 国家列表
const countryList = ( fn, errorFn, dataLevel ) => {
  new Fetch('countries', 'get', '', fn, errorFn, dataLevel)
}
//设置密码
const setPassword = ( data, fn, errorFn, dataLevel ) => {
  new Fetch('set/password', 'post', data, fn, errorFn, dataLevel)
}

export {
  loginApi,
  profileApi,
  geetest,
  getLoginCode,
  captchaLogin,
  getRegisterCode,
  register,
  getForgotCode,
  forgot,
  imporveInformation,
  informationOptions,
  profileCheck,
  countryList,
  setPassword
}
