<script>
  import CategoryHover from './CategoryHover.vue'
  export default {
    props: {
      item: {
        type: Object,
        default: function () {
          return {
            icon: '',
            label: ''
          }
        }
      }
    },
    components:{
      CategoryHover
    },
    data(){
      return{
        isShowCategory: false,
      }
    },
    methods: {
      handleClickItem(){
        this.$emit('itemClick', this.item)
      },
      handleMouseOver(item){
        if(item.name === 'course' || item.name === 'material' || item.name === 'community'){
          this.isShowCategory = true
        }
      },
      handleMouseLeave(item){
         this.isShowCategory = false
      }
    }
  }
</script>

<template>
  <div class="float-bar-item-wrapper"
       @mouseover.stop.prevent="handleMouseOver(item)"
       @mouseleave.stop.prevent="handleMouseLeave(item)"
  >
    <div class="float-bar-item"@click="handleClickItem"
        >
      <div class="item-icon">
        <i class="iconfont" :class="item?.icon"></i>
      </div>
      <div class="item-title">{{ item?.label }}</div>
      <CategoryHover v-show="isShowCategory" :type="item.name" class="category-list"></CategoryHover>
    </div>
  </div>


</template>

<style scoped lang="less">
  .float-bar-item-wrapper{
    padding: 0 14px;
    width: 60px;
    position: relative;
  }
  .float-bar-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 74px;
    color: rgba(0, 0, 0, 0.6);
    border-bottom: 1px solid #f5f5f5;
    cursor: pointer;
    position: relative;
    .item-title {
      font-size: 12px;
    }
    .item-icon{
      .iconfont{
        font-size: 30px;
      }
      .iconfont:before{
        margin-right: 0;
      }
    }
  }
  .float-bar-item:hover{
    color: #29d087;
  }

</style>
