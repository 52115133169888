import * as Types from "./mutationTypes.js";

export default {
  setVideoMode: ({ commit }, status) => {
    commit(Types.SET_VIDEO_MODE, status);
  },
  setDeviceState: ({ commit }, status) => {
    commit(Types.SET_DEVICE_STATE, status);
  },
  setShowConnectResult: ({ commit }, status) => {
    commit(Types.SET_SHOW_CONNECT_RESULT, status);
  },
  setTestStatus: ({ commit }, status) => {
    commit(Types.SET_TEST_STATUS, status);
  },
};
