<template>
  <div class="login">
    <div class="title">设置密码</div>
    <div class="second-title">密码必须包含数字与字母，长度在8-32位</div>
    <el-form :model="registerForm" :rules="rules" ref="loginForm">
      <el-form-item prop="password">
        <template>
          <el-input
            class="registerForm password"
            show-password
            v-model="registerForm.password"
            placeholder="密码必须包含数字，且必须包含字母或符号"
            clearable
          ></el-input>
        </template>
      </el-form-item>
      <el-form-item style="margin-bottom: 0">
        <el-button style="width: 100%" type="primary" @click="handleSetPassword"
          >确认</el-button
        >
        <span class="jump" @click="closeDialog">跳过</span>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import "./gt.js";
import * as Api from "@/api/login/login";
import { Message } from "element-ui";
import store from "@/store";
import { mapActions } from "vuex";

export default {
  name: "RegisterOrForget",
  data() {
    let passwrodReg = /^(?=.*[0-9])(?=.*[a-zA-Z!@_#$%^&*()\-+=,.?]).{8,32}$/;

    let validatePassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("密码不能为空"));
      } else if (value.length < 8) {
        callback(new Error("密码长度不能小于8位"));
      } else {
        if (this.loginType === 3 || this.loginType === 4) {
          if (!passwrodReg.test(value)) {
            callback(
              new Error(
                "密码必须包含数字，且必须包含字母或其它符号（!@_#$%^&*()-+=,.?）"
              )
            );
          } else {
            callback();
          }
        } else {
          callback();
        }
      }
    };

    return {
      registerForm: {
        password: "",
      },
      rules: {
        password: [{ validator: validatePassword, trigger: "blur" }],
      },
    };
  },
  computed: {},
  methods: {
    handleSetPassword() {
      let params = {
        password: this.registerForm.password,
      };
      Api.setPassword(params, () => {
        this.closeDialog();
      });
    },
    closeDialog() {
      this.setFirstPasswordStatus(false);
    },
    ...mapActions(["setFirstPasswordStatus"]),
  },
};
</script>

<style lang="less">
@import "../../assets/style/lib-base";

.login {
  & > .title {
    font-size: @fontSizePrice;
    text-align: center;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .second-title {
    width: 100%;
    text-align: center;
  }
  .jump {
    display: block;
    width: 100%;
    text-align: center;
    cursor: pointer;
    font-size: 12px;
  }
  .password {
    margin-top: 20px;
  }

  & > .el-form {
    .el-form-item {
      margin-bottom: 42px;

      input {
        border: none;
        border-bottom: 1px solid #e6e6e6;
        border-radius: 0;
      }
    }

    & > .verification {
      .el-form-item__content {
        display: flex;
        width: 100%;
        justify-content: space-between;
        border-bottom: 1px solid #e6e6e6;

        .verification-code {
          width: 60%;

          input {
            border: none;
          }
        }

        .verification-btn {
          width: 40%;
          text-align: right;
          color: @papaGreen;
          cursor: pointer;
        }

        .form_button--disabled {
          color: #8c939d;
          cursor: not-allowed;
        }
      }
    }
  }

  & > .login-btn {
    .btnGreen(100%, 44px, 5px);
  }

  & > .change-type {
    width: 100%;
    margin-bottom: 42px;
    text-align: right;
    color: #cfd2d8;

    & > .type-btn {
      cursor: pointer;
      color: #606266;
    }
  }

  .code-login {
    width: 100%;
    text-align: center;
    margin: 30px 0 0;
    cursor: pointer;
  }
}
</style>
