import { render, staticRenderFns } from "./CategoryHover.vue?vue&type=template&id=6198e41f&scoped=true"
import script from "./CategoryHover.vue?vue&type=script&lang=js"
export * from "./CategoryHover.vue?vue&type=script&lang=js"
import style0 from "./CategoryHover.vue?vue&type=style&index=0&id=6198e41f&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6198e41f",
  null
  
)

export default component.exports