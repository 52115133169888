import * as Types from './mutationTypes.js'

export default {
  setNextMsgSeq: ({commit}, seq) => {
    commit(Types.SET_NEXT_MSG_SEQ, seq)
  },
  setIsCompleted: ({commit}, status) => {
    commit(Types.SET_IS_COMPLETED, status)
  },
  setTim: ({commit}, tim) => {
    commit(Types.SET_TIM, tim)
  },
  setImSdkStatus: ({commit},status) =>{
    commit(Types.SET_IM_SDK_STATUS,status)
  }
}
