import Fetch from '@/utils/fetch.js';

// 评分
const trainingPlansApi = (type, id, data, fn, errorFn, dataLevel = 'v2') => {
  new Fetch(`training/speaking/appointments/${id}/comments`, type, data, fn, errorFn, dataLevel);
};

// 老师印象标签
const trainingTagesApi = (data, fn, errorFn, dataLevel = 'v2') => {
  new Fetch(`training/speaking/tags`, 'get', data, fn, errorFn, dataLevel);
};

// 查看详情
const trainingLookDetaulApi = (id, data, fn, errorFn, dataLevel = 'v2') => {
  new Fetch(`training/speaking/appointments/${id}`, 'get', data, fn, errorFn, dataLevel);
};

// 开通记录
const openRecordApi = (data, fn, errorFn, dataLevel = 'v2') => {
  new Fetch(`training/speaking/account_records`, 'get', data, fn, errorFn, dataLevel);
};

// 信息填写
const userInfoApi = (type, data, fn, errorFn, dataLevel = 'v1') => {
  new Fetch(`me`, type, data, fn, errorFn, dataLevel);
};

// 取消预约
const cancelMake = (id, data, fn, errorFn, dataLevel = 'v2') => {
  new Fetch(`training/speaking/appointments/${id}/cancel`, 'put', data, fn, errorFn, dataLevel)
}



export {
  trainingPlansApi,
  trainingTagesApi,
  trainingLookDetaulApi,
  openRecordApi,
  userInfoApi,
  cancelMake
}