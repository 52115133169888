import * as Types from './moutationTypes.js'

export default {
  [Types.SET_TRTC_CLIENT] (state, trtcClient) {
    state.trtcClient = trtcClient
  },
  [Types.SET_LOCAL_STREAM] (state, localStream) {
    state.localStream = localStream
  },
  [Types.SET_IS_PUSHING] (state, status) {
    state.isPushing = status
  },

  [Types.SET_SCREEN_STREAM] (state, screenStream) {
    state.screenStream = screenStream
  },
  [Types.SET_REMOTE_VIDEO_STREAM] (state, remoteVideoScream) {
    state.teacherStream = remoteVideoScream
  },
  [Types.SET_VOLUME_VALUE] (state, value) {
    state.volumeValue = value
  },
  [Types.ADD_STUDENT_STREAM] (state, stream) {
    state.studentStream.push(stream)
  },
  [Types.SET_STUDENT_STREAM] (state, streamArr) {
    state.studentStream = streamArr
  }
}
