import * as Types from './mutationTypes'

export default {
  [Types.SET_IS_SHOW_PAINT_BOX] (state, status) {
    state.isShowPaintBox = status
  },
  [Types.SET_FILE_INFO_LIST] (state, fileList) {
    state.fileInfoList = fileList
  },
  [Types.SET_CURRENT_FILE] (state, file) {
    state.currentFile = file
  },
  [Types.SET_FILEBOX_STATUS] (state, status) {
    state.isShowFileListBox = status
  }

}

