import Fetch from '@/utils/fetch.js'
import { getCourseMaterialUrl } from '../course/course'

// 我的活动
const myActivity = ( activityType, data, fn, errorFn, dataLevel ) => {
  // new Fetch('activities/community_group_records?type=1', 'get', data, fn, errorFn, dataLevel)
  activity(activityType, data, fn, errorFn, dataLevel)
}

// 公益课 即将开课
const publicCourse = ( activityType, data, fn, errorFn, dataLevel ) => {
  // new Fetch('activities/community_group_records?type=2', 'get', data, fn, errorFn, dataLevel)
  activity(activityType, data, fn, errorFn, dataLevel)
}


// 公益课 往期视频
const publicCourseHistory = ( data, fn, errorFn, version ) => {
  new Fetch('course_plans/classes/free', 'get', data, fn, errorFn, version)
}

// 直播课
// 1v1
const userCourse = ( data, fn, errorFn, dataLevel ) => {
  new Fetch('course_plans/personal', 'get', data, fn, errorFn, dataLevel)
}
// 班课
const userCourseClass = ( data, fn, errorFn, dataLevel ) => {
  new Fetch('course_plans/classes/charge', 'get', data, fn, errorFn, dataLevel)
}

/**
 * 活动
 * @param {activityType} activityType
 * @param {Number} activity 是否过期
 * */
const activity = ( activityType, data, fn, errorFn, dataLevel ) => {
  new Fetch(`activity/community_groups/records/${activityType}`, 'get', data, fn, errorFn, dataLevel)
}

/**
 *日历
 *
 */
// 班课
const calendar = ( data, fn, errorFn, dataLevel = 'v1' ) => {
  new Fetch('course_plans/classes/schedules/calendar', 'get', data, fn, errorFn, dataLevel)
}

// 1v1
const calendarPrivate = ( data, fn, errorFn, dataLevel = 'v1' ) => {
  new Fetch('course_plans/personal/tasks/calendar', 'get', data, fn, errorFn, dataLevel)
}

// 优惠券
const couponList = ( fn, errorFn, dataLevel ) => {
  new Fetch('coupons/course', 'get', '', fn, errorFn, dataLevel)
}
// 优惠券
const coupon = ( courseId, data, fn, errorFn, dataLevel ) => {
  new Fetch(`courses/${courseId}/coupons`, 'get', data, fn, errorFn, dataLevel)
}
// 兑换优惠券
const getCoupon = ( data, fn, errorFn, dataLevel ) => {
  new Fetch('course/coupon_activation_codes', 'post', data, fn, errorFn, dataLevel)
}

// 订单
const userOrder = ( type, data, fn, errorFn, dataLevel, id = '' ) => {
  new Fetch(`course/orders/${id}`, type, data, fn, errorFn, 'v3')
}


// 关闭订单
const closeOrder = ( fn, errorFn, dataLevel, id = '' ) => {
  new Fetch(`course/orders/${id}`, 'delete', '', fn, errorFn, dataLevel)
}


// 用户信息

const profile = ( type, data, fn, errorFn, dataLevel ) => {
  new Fetch('me', type, data, fn, errorFn, dataLevel)
}

// avatar
const editAvatar = ( type, data, fn, errorFn ) => {
  new Fetch('me/avatar', type, data, fn, errorFn, 'v2')
}




// 收货信息

const address = ( type, data, fn, errorFn, id = '', dataLevel ) => {
  new Fetch(`delivery_addresses/${id}`, type, data, fn, errorFn, dataLevel)
}


// 重置密码

const resetPasswored = ( data, fn, errorFn ) => {
  new Fetch('reset/password', 'post', data, fn, errorFn)
}

// 省市区数据

const areas = ( fn, errorFn, dataLevel ) => {
  new Fetch('areas', 'get', '', fn, errorFn, dataLevel)
}


/**
 * 过期课程
 *  @params class_type = 2 班课
 *  @params class_type = 11v1
 * */
const expiredCourse = ( data, fn, errorFn, dataLevel ) => {
  new Fetch('course_plans', 'get', data, fn, errorFn, dataLevel)
}

const publicCourseware = ( fn, errorFn, dataLevel ) => {
  new Fetch('coursewares/public', 'get', '', fn, errorFn, dataLevel)
}

//获取腾讯云的token
const getTxToken = ( data, fn, errorFn, dataLevel ) => {
  new Fetch('upload/tencent_cloud/federation_token', 'get', data, fn, errorFn, dataLevel)
}


export {
  couponList,
  myActivity,
  publicCourse,
  userCourse,
  profile,
  userOrder,
  coupon,
  calendar,
  publicCourseHistory,
  closeOrder,
  address,
  resetPasswored,
  areas,
  calendarPrivate,
  editAvatar,
  userCourseClass,
  getCoupon,
  publicCourseware,
  getCourseMaterialUrl,
  getTxToken
}
