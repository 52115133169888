<script>
  import { mapState } from 'vuex'
  import QrcodeHover from '@/components/FloatBar/components/QrcodeHover.vue'

  export default {
    data () {
      return {
        list: [],
        activeCategory: ''
      }
    },
    props: {
      type: {
        type: String,
        default: 'course'
      }
    },
    components: {
      QrcodeHover
    },
    computed: {
      ...mapState({
        categoryList: state => state.common.categoryTypes
      })
    },
    methods: {
      callService () {
        this.categoryData = this.$store.state.common.categoryTypes
        this.categoryData.forEach(item => {
          if (item.abbr === this.activeScoreSwipeCategory) {
            this.isShowQrcode = true
            this.qrImage = item.qrcode_image_url
          }
        })
      },
      onSetCurrentCategory ( id ) {
        this.activeCategory = id
      }
    },
    watch: {
      categoryList: {
        handler ( list ) {
          if (list) {
            const _list = []
            list.forEach(item => {
              if ([1, 2, 4, 3, 6].includes(item.id)) {
                if(this.type === 'community'){
                  if(item.public_class_qr_code_image_url){
                    _list.push(item)
                  }
                }else{
                  _list.push(item)
                }
              }
            })
            this.list = _list
            this.activeCategory = 1
          }
        },
        deep: true,
        immediate: true
      }
    }
  }
</script>

<template>
  <div class="category-hover-wrapper">
    <div class="category-hover">
      <div class="item" v-for="item in list" :key="item.id"
           @mouseover="onSetCurrentCategory(item.id)"
           @mouseleave="onSetCurrentCategory(1)"
           :class="activeCategory === item.id ? 'item-active' : ''"
      >
        {{ item.name }}
        <qrcode-hover class="category-qrcode" :type="type" :category-item="item"
                      :style="activeCategory === item.id ? 'display: block' : ''"></qrcode-hover>
      </div>
    </div>
    <div class="triangle"></div>
  </div>

</template>

<style scoped lang="less">
  .category-hover-wrapper {
    position: absolute;
    left: -120px;
    top: 16px;
    width: 105px;
  }

  .category-hover {
    width: 95px;
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 12px;
    flex-direction: column;
    box-shadow: 0 2px 16px 0 rgba(61, 61, 61, 0.1);

    .item {
      width: 95px;
      height: 60px;
      text-align: center;
      line-height: 60px;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.9);
      position: relative;
      z-index: 2;

      .category-qrcode {
        display: none;
      }
    }
    .item-active{
      background: rgba(233, 250, 243, 1);
      color: #29D087;
    }
    .item:first-child{
      border-radius: 12px 12px 0 0
    }
    .item:last-child{
      border-radius: 0 0 12px 12px
    }

    .item:hover {
      background: rgba(233, 250, 243, 1);
      color: #29D087;

      .category-qrcode {
        display: flex;
      }
    }
  }

  .triangle {
    width: 20px;
    height: 20px;
    border-radius: 6px;
    background: #fff;
    transform: rotate(45deg);
    position: absolute;
    top: 20px;
    right: 4px;
    z-index: 1
  }
</style>
