import * as Types from "./mutationTypes.js";

export default {
  [Types.SET_VIDEO_MODE](state, status) {
    state.videoMode = status;
  },
  [Types.SET_DEVICE_STATE](state, status) {
    state.deviceState = status;
  },
  [Types.SET_SHOW_CONNECT_RESULT](state, status) {
    state.showConnectResult = status;
  },
  [Types.SET_TEST_STATUS](state, status) {
    state.testStatus = status;
  },
};
